a {
  text-decoration: none; }

.rtl .services-list-popover {
  left: initial; }

.services-list-popover {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 8px -5px;
  position: absolute;
  top: 189px;
  width: 900px;
  z-index: 1; }
  .services-list-popover .filter {
    clear: both;
    padding: 15px;
    text-align: center; }
    .services-list-popover .filter button {
      border: 1px solid #ddd;
      border-radius: 5px;
      cursor: pointer;
      font-family: "Roboto", sans-serif;
      font-size: 13px;
      height: 41px;
      width: 120px; }
      .irc .services-list-popover .filter button {
        background-color: #ffda1a; }
      .nrc .services-list-popover .filter button {
        background-color: #ff7602; }
      .generic .services-list-popover .filter button {
        background-color: #29991f; }
      .mc .services-list-popover .filter button {
        background-color: #e60000; }
      .pg .services-list-popover .filter button {
        background-color: #59c2ed; }
      .cuenta .services-list-popover .filter button {
        background-color: #29991f; }
      .ri .services-list-popover .filter button {
        background-color: #cf5c36; }
      .irc .services-list-popover .filter button {
        color: #000000; }
      .nrc .services-list-popover .filter button {
        color: #ffffff; }
      .generic .services-list-popover .filter button {
        color: #ffffff; }
      .mc .services-list-popover .filter button {
        color: #ffffff; }
      .pg .services-list-popover .filter button {
        color: #ffffff; }
      .cuenta .services-list-popover .filter button {
        color: #ffffff; }
      .ri .services-list-popover .filter button {
        color: #ffffff; }
