a {
  text-decoration: none; }

.warning-dialog-container, .warning-dialog-container-yellow, .warning-dialog-container-red, .warning-dialog-container-green {
  padding: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  color: black; }
  .warning-dialog-container p, .warning-dialog-container-yellow p, .warning-dialog-container-red p, .warning-dialog-container-green p {
    color: black; }

.warning-dialog-close {
  justify-self: flex-end;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: flex-end;
  cursor: pointer; }

.irc .warning-dialog-container-yellow {
  background-color: #ffda1a; }

.nrc .warning-dialog-container-yellow {
  background-color: #ff7602; }

.generic .warning-dialog-container-yellow {
  background-color: #29991f; }

.mc .warning-dialog-container-yellow {
  background-color: #e60000; }

.pg .warning-dialog-container-yellow {
  background-color: #59c2ed; }

.cuenta .warning-dialog-container-yellow {
  background-color: #29991f; }

.ri .warning-dialog-container-yellow {
  background-color: #cf5c36; }

.warning-dialog-container-red {
  background-color: #e60000;
  color: white; }
  .warning-dialog-container-red p {
    color: white; }

.warning-dialog-container-green {
  background-color: #29991f;
  color: white; }
  .warning-dialog-container-green p {
    color: white; }

.warning-wrapper {
  justify-content: space-between;
  align-items: stretch;
  color: #292633;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 16px;
  font-weight: bold;
  height: auto;
  max-width: 1024px;
  margin: 0 auto;
  padding: 16px;
  position: relative; }
  .warning-wrapper .warning-dialog-container-inner p {
    padding: 0;
    margin: 0; }
  .ltr .warning-wrapper .warning-dialog-container-inner {
    border-left: 1px solid white;
    padding-left: 15px; }
  .rtl .warning-wrapper .warning-dialog-container-inner {
    border-right: 4px solid white;
    padding-right: 15px; }
  .ltr .warning-dialog-container-yellow .warning-wrapper .warning-dialog-container-inner {
    border-left-color: black; }
  .rtl .warning-dialog-container-yellow .warning-wrapper .warning-dialog-container-inner {
    border-right-color: black; }

.warning--hiding {
  padding: 0;
  margin: 0;
  transition: padding 1s; }

.warning--hiding .warning-dialog-container-inner,
.warning--hiding .warning-dialog-close {
  display: none; }
