a {
  text-decoration: none; }

.ArticleDetailFooter {
  margin: 0 auto;
  max-width: 1024px; }
  .ArticleDetailFooter .nav {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    display: flex;
    overflow: hidden;
    padding: 5px 0; }
    @media (min-width: 1000px) {
      .ArticleDetailFooter .nav {
        border-radius: 5px; } }
  .ArticleDetailFooter .divider {
    border-top-color: #ddd; }
  .ArticleDetailFooter .icon {
    padding: 0 10px; }
  .ArticleDetailFooter .page-selector {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin: 0;
    min-height: 60px;
    width: 100%; }
    .ArticleDetailFooter .page-selector:hover h1 {
      text-decoration: underline; }
    .ArticleDetailFooter .page-selector.next {
      justify-content: flex-end;
      padding-left: 10px; }
    .ArticleDetailFooter .page-selector.prev {
      padding-right: 10px; }
    .ArticleDetailFooter .page-selector .caption {
      display: flex;
      flex-flow: column; }
    .ArticleDetailFooter .page-selector small {
      color: gray;
      display: block;
      font-size: 12px;
      width: 100%; }
    .ArticleDetailFooter .page-selector h1 {
      display: block;
      font-size: 15px;
      font-weight: normal;
      margin: 0;
      user-select: none;
      width: 100%; }
  .ArticleDetailFooter .subscription {
    border-top: 1px solid #ccc;
    padding-top: 20px;
    text-align: center; }
    .ArticleDetailFooter .subscription a {
      border-radius: 5px;
      display: inline-block;
      font-size: 13px;
      margin: 30px 0;
      width: auto;
      padding: 20px;
      text-decoration: none; }
      .irc .ArticleDetailFooter .subscription a {
        background-color: #ffda1a; }
      .nrc .ArticleDetailFooter .subscription a {
        background-color: #ff7602; }
      .generic .ArticleDetailFooter .subscription a {
        background-color: #29991f; }
      .mc .ArticleDetailFooter .subscription a {
        background-color: #e60000; }
      .pg .ArticleDetailFooter .subscription a {
        background-color: #59c2ed; }
      .cuenta .ArticleDetailFooter .subscription a {
        background-color: #29991f; }
      .ri .ArticleDetailFooter .subscription a {
        background-color: #cf5c36; }
      .irc .ArticleDetailFooter .subscription a {
        color: #000000; }
      .nrc .ArticleDetailFooter .subscription a {
        color: #ffffff; }
      .generic .ArticleDetailFooter .subscription a {
        color: #ffffff; }
      .mc .ArticleDetailFooter .subscription a {
        color: #ffffff; }
      .pg .ArticleDetailFooter .subscription a {
        color: #ffffff; }
      .cuenta .ArticleDetailFooter .subscription a {
        color: #ffffff; }
      .ri .ArticleDetailFooter .subscription a {
        color: #ffffff; }
      .ArticleDetailFooter .subscription a:hover {
        text-decoration: underline; }
        .irc .ArticleDetailFooter .subscription a:hover {
          background-color: #e6c100; }
        .nrc .ArticleDetailFooter .subscription a:hover {
          background-color: #ce5e00; }
        .generic .ArticleDetailFooter .subscription a:hover {
          background-color: #1e6f16; }
        .mc .ArticleDetailFooter .subscription a:hover {
          background-color: #b30000; }
        .pg .ArticleDetailFooter .subscription a:hover {
          background-color: #2bb1e8; }
        .cuenta .ArticleDetailFooter .subscription a:hover {
          background-color: #1e6f16; }
        .ri .ArticleDetailFooter .subscription a:hover {
          background-color: #aa4928; }
  .ArticleDetailFooter .up {
    display: none; }
  .ArticleDetailFooter input {
    display: none; }
  .ArticleDetailFooter input:checked + label > .up {
    display: flex; }
  .ArticleDetailFooter input:checked + label > .down {
    display: none; }
  .ArticleDetailFooter input:checked ~ ul {
    display: block;
    transition: display 2s ease-in-out; }
