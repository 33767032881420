a {
  text-decoration: none; }

.NotFound {
  background-color: white;
  min-height: 90vh;
  padding: 50px 20px;
  text-align: center; }
  .NotFound i {
    font-size: 80px;
    margin: 20px auto; }
    .irc .NotFound i {
      color: #e6c100; }
    .nrc .NotFound i {
      color: #ce5e00; }
    .generic .NotFound i {
      color: #1e6f16; }
    .mc .NotFound i {
      color: #b30000; }
    .pg .NotFound i {
      color: #2bb1e8; }
    .cuenta .NotFound i {
      color: #1e6f16; }
    .ri .NotFound i {
      color: #aa4928; }
  .NotFound .content a {
    border-radius: 5px;
    display: inline-block;
    margin: 50px auto;
    padding: 20px 40px; }
    .irc .NotFound .content a {
      background-color: #e6c100; }
    .nrc .NotFound .content a {
      background-color: #ce5e00; }
    .generic .NotFound .content a {
      background-color: #1e6f16; }
    .mc .NotFound .content a {
      background-color: #b30000; }
    .pg .NotFound .content a {
      background-color: #2bb1e8; }
    .cuenta .NotFound .content a {
      background-color: #1e6f16; }
    .ri .NotFound .content a {
      background-color: #aa4928; }
    .irc .NotFound .content a {
      color: #000000; }
    .nrc .NotFound .content a {
      color: #ffffff; }
    .generic .NotFound .content a {
      color: #ffffff; }
    .mc .NotFound .content a {
      color: #ffffff; }
    .pg .NotFound .content a {
      color: #ffffff; }
    .cuenta .NotFound .content a {
      color: #ffffff; }
    .ri .NotFound .content a {
      color: #ffffff; }
    .NotFound .content a:hover {
      text-decoration: underline; }
      .irc .NotFound .content a:hover {
        background-color: #ffda1a; }
      .nrc .NotFound .content a:hover {
        background-color: #ff7602; }
      .generic .NotFound .content a:hover {
        background-color: #29991f; }
      .mc .NotFound .content a:hover {
        background-color: #e60000; }
      .pg .NotFound .content a:hover {
        background-color: #59c2ed; }
      .cuenta .NotFound .content a:hover {
        background-color: #29991f; }
      .ri .NotFound .content a:hover {
        background-color: #cf5c36; }
  .NotFound .content .title {
    font-size: 25px;
    font-weight: bold;
    margin: 20px auto; }
  .NotFound .content .message {
    white-space: pre-line; }
