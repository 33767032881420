a {
  text-decoration: none; }

.BottomNav {
  box-shadow: 0px 1px 8px 0px !important; }
  .BottomNav .general-container {
    display: flex;
    justify-content: space-evenly; }
    .BottomNav .general-container .button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1% 0;
      margin-bottom: 1%;
      cursor: pointer; }
      .BottomNav .general-container .button-container.Selected {
        border-bottom: 3px solid; }
        .irc .BottomNav .general-container .button-container.Selected {
          border-bottom-color: #7FB685; }
        .nrc .BottomNav .general-container .button-container.Selected {
          border-bottom-color: #000000; }
        .generic .BottomNav .general-container .button-container.Selected {
          border-bottom-color: #339933; }
        .mc .BottomNav .general-container .button-container.Selected {
          border-bottom-color: #000000; }
        .pg .BottomNav .general-container .button-container.Selected {
          border-bottom-color: #000000; }
        .cuenta .BottomNav .general-container .button-container.Selected {
          border-bottom-color: #339933; }
        .ri .BottomNav .general-container .button-container.Selected {
          border-bottom-color: #9F87AF; }
      .BottomNav .general-container .button-container .BottomButton {
        font-size: 14px;
        margin-top: 2px; }
        .irc .BottomNav .general-container .button-container .BottomButton {
          color: #16697A; }
        .nrc .BottomNav .general-container .button-container .BottomButton {
          color: #000000; }
        .generic .BottomNav .general-container .button-container .BottomButton {
          color: #3D3FDA; }
        .mc .BottomNav .general-container .button-container .BottomButton {
          color: #000000; }
        .pg .BottomNav .general-container .button-container .BottomButton {
          color: #000000; }
        .cuenta .BottomNav .general-container .button-container .BottomButton {
          color: #3D3FDA; }
        .ri .BottomNav .general-container .button-container .BottomButton {
          color: #005377; }
  .rtl .BottomNav .Selected {
    font-family: "Cairo", sans-serif; }
  .BottomNav .Selected > span {
    color: black;
    font-weight: 700; }
    .rtl .BottomNav .Selected > span {
      font-family: "Cairo", sans-serif; }
  .rtl .BottomNav .BottomButton {
    font-family: "Cairo", "Roboto", sans-serif; }
  .language-ur .BottomNav .BottomButton {
    font-family: "NastaleeqNuma", "Cairo", "Roboto", sans-serif; }
  .language-ti .BottomNav .BottomButton {
    font-family: "EthiopicHiwua", sans-serif; }
  .ltr .BottomNav .BottomButton {
    font-family: "Roboto", sans-serif;
    padding: 0; }
  @media (min-width: 1000px) {
    .BottomNav {
      display: none; } }
