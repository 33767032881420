a {
  text-decoration: none; }

.HeaderBar ul {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
  border-top: 1px #717171 solid;
  border-bottom: 1px #717171 solid; }

.HeaderBar ul > li {
  width: 100%;
  display: flex;
  margin: 0;
  background-color: whitesmoke;
  min-height: 60px;
  flex-direction: row;
  align-items: center;
  cursor: pointer; }

.HeaderBar ul > li > h1 {
  font-size: 18px;
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px;
  flex: 1; }

.HeaderBar ul > li > h1 > small {
  font-size: 12px;
  display: block;
  width: 100%;
  color: gray; }

.HeaderBar li:not(:nth-child(1)) {
  border-top: 1px #767676 solid; }

.HeaderBar input {
  display: none; }

.HeaderBar input:checked + label > .up {
  display: flex; }

.HeaderBar input:checked + label > .down {
  display: none; }

.HeaderBar input:checked + label ~ ul {
  display: block;
  transition: display 2s; }

.HeaderBar label {
  min-height: 72px;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.HeaderBar h1 {
  display: block;
  font-size: 20px;
  flex: 1;
  margin: 0;
  padding: 20px 0;
  width: 100%; }
  @media (min-width: 1000px) {
    .HeaderBar h1 {
      font-size: 30px;
      line-height: 36px; } }

.HeaderBar h1 > small {
  font-size: 12px;
  display: block;
  width: 100%;
  color: gray; }

.HeaderBar .up {
  display: none; }

.HeaderBar .up,
.HeaderBar .down {
  width: 30px; }

.HeaderBar i {
  padding-right: 10px;
  padding-left: 10px;
  font-size: 25px;
  width: 24px;
  height: 24px;
  text-align: center; }
