a {
  text-decoration: none; }

.SearchPage .ArticleListBody .tiles-desktop {
  min-height: initial; }

.ArticleListBody {
  margin: 0 auto;
  max-width: 1024px; }
  .ArticleListBody .article-title:hover {
    cursor: pointer;
    text-decoration: underline; }
  .ArticleListBody .CategoryTitle {
    height: 72px;
    padding-left: 16px;
    padding-right: 16px;
    text-transform: uppercase;
    background: #f2f2f2;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .ArticleListBody .overlay {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 0; }
  .ArticleListBody .article-container {
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .ArticleListBody .tiles-desktop {
    display: none; }
  .ArticleListBody ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .ArticleListBody ul li.selected {
      font-weight: bold; }
  @media (min-width: 1000px) {
    .ArticleListBody .tiles-mobile {
      display: none; }
    .ArticleListBody .tiles-desktop {
      display: inline-block;
      min-height: 600px;
      width: 100%; }
      .ArticleListBody .tiles-desktop .tile {
        background-color: white;
        border: 1px solid #ddd;
        box-sizing: border-box;
        overflow: hidden;
        padding: 5px; }
        .ArticleListBody .tiles-desktop .tile:hover {
          border: 1px solid #bbb; }
          .ArticleListBody .tiles-desktop .tile:hover h2 {
            text-decoration: underline; }
          .ArticleListBody .tiles-desktop .tile:hover .text {
            background-color: white; }
        .ArticleListBody .tiles-desktop .tile .text {
          background-color: white;
          border-top: 1px solid #ddd;
          cursor: pointer;
          margin-top: 5px;
          padding: 10px; }
          .ArticleListBody .tiles-desktop .tile .text .category {
            align-items: center;
            display: flex;
            font-size: 14px;
            font-weight: lighter;
            padding-bottom: 15px; }
            .ArticleListBody .tiles-desktop .tile .text .category i, .ArticleListBody .tiles-desktop .tile .text .category svg {
              text-align: center;
              min-width: 20px; }
            .ArticleListBody .tiles-desktop .tile .text .category span {
              align-items: center;
              display: flex;
              height: 33px;
              padding: 0 5px; }
          .ArticleListBody .tiles-desktop .tile .text h2 {
            font-size: 16px;
            font-weight: normal;
            height: 64px;
            margin: 0 0 7px 0;
            overflow: hidden;
            text-overflow: ellipsis; }
          .ArticleListBody .tiles-desktop .tile .text .author {
            color: gray;
            font-size: 14px;
            font-weight: 300; }
            .ArticleListBody .tiles-desktop .tile .text .author span {
              font-style: italic; }
    .ArticleListBody ul li.tile {
      display: inline-block;
      margin: 1%;
      width: 31%; }
      .ArticleListBody ul li.tile .img-viewport {
        cursor: pointer;
        display: flex;
        height: 154px !important;
        overflow: hidden;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center; }
        .ArticleListBody ul li.tile .img-viewport img {
          width: 100%; }
      .ArticleListBody ul li.tile.selected {
        background-color: #fdda14; }
    .ArticleListBody ul li ul {
      position: absolute;
      left: 2vw;
      right: 6vw;
      background-color: white;
      margin-top: 20px !important;
      box-shadow: 6px 6px 10px -2px; }
    .ArticleListBody ul li ul li {
      width: 33%;
      margin: 0px;
      box-shadow: 0px 0px 0px 0px;
      line-height: 45px;
      border-bottom: 1px solid #e1dfdf;
      font-size: 16px; }
    .ArticleListBody .line {
      display: none; }
    .ArticleListBody .material-icons {
      font-size: 20px; }
    .ArticleListBody ul li .container {
      width: calc(100% - 32px);
      display: inline-table;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      user-select: none; } }
  .ArticleListBody ul li .container {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .ArticleListBody ul li .container:active {
      background-color: #f6f6f6; }
  .ArticleListBody > ul li .container > i {
    padding-right: 10px;
    width: 25px; }
  .ArticleListBody ul li .inner-container {
    padding: 15px;
    display: flex;
    flex-direction: row; }
    .ArticleListBody ul li .inner-container div {
      line-height: 160%;
      user-select: none; }
  .ArticleListBody ul > li > label {
    font-size: 18px;
    display: block;
    margin: 0;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .ArticleListBody ul > li > ul {
    background-color: #eee;
    border-left: 2px solid black;
    border-right: 2px solid black;
    display: none;
    list-style: none;
    margin: 0; }
    .ArticleListBody ul > li > ul.sub-categories {
      margin-right: 50px;
      margin-left: 50px;
      padding-right: 10px;
      padding-left: 10px; }
      .irc .ArticleListBody ul > li > ul.sub-categories {
        border-left-color: #ffda1a; }
      .nrc .ArticleListBody ul > li > ul.sub-categories {
        border-left-color: #ff7602; }
      .generic .ArticleListBody ul > li > ul.sub-categories {
        border-left-color: #29991f; }
      .mc .ArticleListBody ul > li > ul.sub-categories {
        border-left-color: #e60000; }
      .pg .ArticleListBody ul > li > ul.sub-categories {
        border-left-color: #59c2ed; }
      .cuenta .ArticleListBody ul > li > ul.sub-categories {
        border-left-color: #29991f; }
      .ri .ArticleListBody ul > li > ul.sub-categories {
        border-left-color: #cf5c36; }
      .irc .ArticleListBody ul > li > ul.sub-categories {
        border-right-color: #ffda1a; }
      .nrc .ArticleListBody ul > li > ul.sub-categories {
        border-right-color: #ff7602; }
      .generic .ArticleListBody ul > li > ul.sub-categories {
        border-right-color: #29991f; }
      .mc .ArticleListBody ul > li > ul.sub-categories {
        border-right-color: #e60000; }
      .pg .ArticleListBody ul > li > ul.sub-categories {
        border-right-color: #59c2ed; }
      .cuenta .ArticleListBody ul > li > ul.sub-categories {
        border-right-color: #29991f; }
      .ri .ArticleListBody ul > li > ul.sub-categories {
        border-right-color: #cf5c36; }
      .ltr .ArticleListBody ul > li > ul.sub-categories {
        border-left-width: 3px;
        border-left-style: solid; }
      .rtl .ArticleListBody ul > li > ul.sub-categories {
        border-right-width: 3px;
        border-right-style: solid; }
    .ArticleListBody ul > li > ul > li {
      border-top: 1px solid #ddd;
      font-size: 14px;
      font-weight: normal; }
  .ArticleListBody .category-name {
    flex-grow: 9;
    font-size: 16px; }
  .ArticleListBody hr {
    padding: 0;
    margin: auto;
    border: 0;
    border-top-width: 1px;
    border-top-color: #ddd;
    border-style: solid;
    border-radius: 1px;
    height: 0;
    width: 95%; }
  .ArticleListBody .up {
    display: none;
    flex-grow: 1;
    align-self: flex-end;
    align-items: flex-end;
    flex-direction: column; }
  .ArticleListBody .down {
    display: flex;
    flex-grow: 1;
    align-self: flex-end;
    align-items: flex-end;
    flex-direction: column; }
  .ArticleListBody input {
    display: none; }
  .ArticleListBody input:checked + label > .up {
    display: flex; }
  .ArticleListBody input:checked + label > .down {
    display: none; }
  .ArticleListBody input:checked ~ ul {
    display: block;
    transition: display 2s ease-in-out; }
  .ArticleListBody .icon-container {
    display: flex;
    margin: 0 7px;
    width: 19px;
    justify-content: center; }
