a {
  text-decoration: none; }

.ActionsBar .left .btn, .ActionsBar .left #articles-list-dropdown, .ActionsBar .social .social-btn, .ActionsBar #articles-list-popover {
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box; }

.ActionsBar .left .btn, .ActionsBar .left #articles-list-dropdown, .ActionsBar .social .social-btn {
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
  padding: 2px 5px; }
  .ActionsBar .left .btn:hover, .ActionsBar .left #articles-list-dropdown:hover, .ActionsBar .social .social-btn:hover {
    border-color: #bbb; }

.ActionsBar {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  height: 65px;
  margin-bottom: 20px;
  padding: 10px 5px; }
  .ActionsBar div {
    display: inherit;
    height: 100%; }
  .ActionsBar svg {
    font-size: 16px;
    margin: 0 7px; }
  .ActionsBar .left .btn {
    display: inherit;
    height: inherit;
    overflow: hidden; }
    .ActionsBar .left .btn span {
      padding: 0 10px; }
  .ActionsBar .left #articles-list-dropdown {
    justify-content: space-between;
    width: 400px; }
    .ActionsBar .left #articles-list-dropdown:active {
      border-style: solid; }
    .ActionsBar .left #articles-list-dropdown .content {
      align-items: inherit;
      height: unset; }
      .ActionsBar .left #articles-list-dropdown .content i {
        padding: 0 5px; }
      .ActionsBar .left #articles-list-dropdown .content span {
        padding: 0 5px; }
  .ActionsBar .left #services-list-map-toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5px; }
    .ActionsBar .left #services-list-map-toggle div {
      display: inline-block; }
    .ActionsBar .left #services-list-map-toggle input {
      cursor: pointer;
      width: 50px;
      height: 25px;
      margin: -9px 0 0; }
    .ActionsBar .left #services-list-map-toggle span {
      font-size: 12px;
      text-align: center; }
    .ActionsBar .left #services-list-map-toggle .toggle-btn {
      border: 1px solid transparent;
      box-sizing: border-box;
      margin: 0 0 5px; }
      .ActionsBar .left #services-list-map-toggle .toggle-btn div {
        border: 1px solid #ddd;
        box-shadow: none;
        margin: -1px 0 0 -1px; }
      .ActionsBar .left #services-list-map-toggle .toggle-btn.hover {
        border: 1px solid #bbb; }
        .ActionsBar .left #services-list-map-toggle .toggle-btn.hover div {
          border: 1px solid #ccc; }
  .ActionsBar #articles-list-popover {
    box-shadow: 0 0 8px -5px;
    height: max-content;
    margin: 43px 5px;
    position: absolute;
    width: 400px;
    z-index: 2; }
    .ActionsBar #articles-list-popover li {
      align-items: center;
      display: flex;
      font-size: 14px;
      min-height: 22px;
      padding: 5px; }
      .ActionsBar #articles-list-popover li:hover {
        background-color: #ccc !important;
        cursor: pointer; }
      .ActionsBar #articles-list-popover li i {
        display: inline-block;
        text-align: center;
        min-width: 32px; }
      .ActionsBar #articles-list-popover li.active {
        background-color: #ddd; }
  .ActionsBar .social .social-btn {
    justify-content: center;
    height: inherit;
    position: relative;
    width: 45px; }
    .ActionsBar .social .social-btn .copied {
      background-color: gray;
      border-radius: 3px;
      color: white;
      font-size: 12px;
      padding: 3px;
      position: absolute;
      right: 0;
      top: 50px; }
  .ActionsBar .line {
    margin: 0 5px;
    border-right: 1px solid #ddd; }

.rtl .ActionsBar .copied {
  right: unset !important;
  left: 0; }
