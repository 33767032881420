a {
  text-decoration: none; }

.ArticleDetailBody {
  max-width: 1024px;
  margin: 0 auto 20px; }
  .ArticleDetailBody.loading {
    opacity: 0.2;
    transition: opacity 0.2s ease-in-out; }
  .ArticleDetailBody.loaded {
    opacity: 1;
    transition: opacity 0.2s ease-in-out; }
  .ArticleDetailBody u {
    text-decoration: none; }
  .ArticleDetailBody a:hover {
    text-decoration: none;
    border-bottom: 2px black solid; }
    .irc .ArticleDetailBody a:hover {
      border-bottom-color: #ffda1a; }
    .nrc .ArticleDetailBody a:hover {
      border-bottom-color: #ff7602; }
    .generic .ArticleDetailBody a:hover {
      border-bottom-color: #29991f; }
    .mc .ArticleDetailBody a:hover {
      border-bottom-color: #e60000; }
    .pg .ArticleDetailBody a:hover {
      border-bottom-color: #59c2ed; }
    .cuenta .ArticleDetailBody a:hover {
      border-bottom-color: #29991f; }
    .ri .ArticleDetailBody a:hover {
      border-bottom-color: #cf5c36; }
  .ArticleDetailBody .HeaderBar h1 {
    text-align: center; }
  @media (min-width: 1000px) {
    .ArticleDetailBody .HeaderBar h1 {
      padding: 40px 0; } }
  .ArticleDetailBody .pos,
  .ArticleDetailBody .neg {
    display: none;
    visibility: hidden;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0; }
  .ArticleDetailBody .pos + ul,
  .ArticleDetailBody .neg + ul {
    padding: 0; }
    .ArticleDetailBody .pos + ul li,
    .ArticleDetailBody .neg + ul li {
      list-style: none; }
      .ArticleDetailBody .pos + ul li::before,
      .ArticleDetailBody .neg + ul li::before {
        font-family: "Font Awesome 5 Pro"; }
  .ArticleDetailBody .pos + ul li::before {
    display: none;
    content: "\f00c ";
    color: #29991f;
    font-weight: 900; }
  .ArticleDetailBody .pos + ul li svg {
    color: #29991f;
    font-weight: 900; }
  .ArticleDetailBody .neg + ul li::before {
    display: none;
    content: "\f00d ";
    color: #e60000; }
  .ArticleDetailBody .neg + ul li svg {
    color: #e60000;
    font-weight: 900; }
  .ArticleDetailBody .Facebook {
    text-align: center;
    width: 100%; }
  .ArticleDetailBody .YouTube {
    width: 100%; }
  .ArticleDetailBody .Instagram iframe {
    max-width: 100% !important; }
  .ArticleDetailBody blockquote {
    border-left-width: 3px;
    border-left-style: solid;
    padding-left: 1em;
    margin: 2px; }
    .irc .ArticleDetailBody blockquote {
      border-left-color: #ffd601; }
    .nrc .ArticleDetailBody blockquote {
      border-left-color: #e86a00; }
    .generic .ArticleDetailBody blockquote {
      border-left-color: #23841b; }
    .mc .ArticleDetailBody blockquote {
      border-left-color: #cd0000; }
    .pg .ArticleDetailBody blockquote {
      border-left-color: #42baeb; }
    .cuenta .ArticleDetailBody blockquote {
      border-left-color: #23841b; }
    .ri .ArticleDetailBody blockquote {
      border-left-color: #be512d; }
  .ArticleDetailBody hr {
    width: 10%;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    height: 0;
    color: #000000;
    border-color: #000000; }
    .ltr .ArticleDetailBody hr {
      margin-left: 0; }
    .rtl .ArticleDetailBody hr {
      margin-right: 0; }
    .irc .ArticleDetailBody hr {
      color: #ffda1a; }
    .nrc .ArticleDetailBody hr {
      color: #ff7602; }
    .generic .ArticleDetailBody hr {
      color: #29991f; }
    .mc .ArticleDetailBody hr {
      color: #e60000; }
    .pg .ArticleDetailBody hr {
      color: #59c2ed; }
    .cuenta .ArticleDetailBody hr {
      color: #29991f; }
    .ri .ArticleDetailBody hr {
      color: #cf5c36; }
    .irc .ArticleDetailBody hr {
      border-color: #ffda1a; }
    .nrc .ArticleDetailBody hr {
      border-color: #ff7602; }
    .generic .ArticleDetailBody hr {
      border-color: #29991f; }
    .mc .ArticleDetailBody hr {
      border-color: #e60000; }
    .pg .ArticleDetailBody hr {
      border-color: #59c2ed; }
    .cuenta .ArticleDetailBody hr {
      border-color: #29991f; }
    .ri .ArticleDetailBody hr {
      border-color: #cf5c36; }
  .ArticleDetailBody .title {
    width: 100%;
    display: flex;
    margin: 0;
    background-color: #f0f0f0;
    min-height: 60px;
    flex-direction: row;
    align-items: center; }
  .ArticleDetailBody .title > h1 {
    font-size: 18px;
    display: block;
    width: 100%;
    margin: 0;
    padding: 10px;
    flex: 1; }
  .ArticleDetailBody .title > h1 > small {
    font-size: 12px;
    display: block;
    width: 100%;
    color: gray; }
  .ArticleDetailBody article {
    font-size: 16px;
    font-weight: 300; }
    .ArticleDetailBody article > div {
      padding-top: 30px; }
    .ArticleDetailBody article p {
      line-height: 150%; }
      .ArticleDetailBody article p a {
        text-decoration: underline; }
    .ArticleDetailBody article s a {
      display: block;
      width: fit-content; }
    .ArticleDetailBody article li a {
      text-decoration: underline; }
    .ArticleDetailBody article s:not(.Read-More) a {
      display: block; }
      @media (min-width: 1000px) {
        .ArticleDetailBody article s:not(.Read-More) a:before {
          border: 2px solid;
          display: inline-block;
          height: 6px;
          margin-right: 5px;
          width: 2px;
          color: blue !important; }
          .irc .ArticleDetailBody article s:not(.Read-More) a:before {
            border-color: #ffda1a; }
          .nrc .ArticleDetailBody article s:not(.Read-More) a:before {
            border-color: #ff7602; }
          .generic .ArticleDetailBody article s:not(.Read-More) a:before {
            border-color: #29991f; }
          .mc .ArticleDetailBody article s:not(.Read-More) a:before {
            border-color: #e60000; }
          .pg .ArticleDetailBody article s:not(.Read-More) a:before {
            border-color: #59c2ed; }
          .cuenta .ArticleDetailBody article s:not(.Read-More) a:before {
            border-color: #29991f; }
          .ri .ArticleDetailBody article s:not(.Read-More) a:before {
            border-color: #cf5c36; } }
    .ArticleDetailBody article ol li {
      line-height: 1.5;
      padding-bottom: 15px; }
  .ArticleDetailBody .hero {
    align-items: center;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: center; }
  @media (min-width: 1000px) {
    .ArticleDetailBody .hero {
      overflow: hidden; } }
  .ArticleDetailBody .hero > img {
    width: 100%; }
  .ArticleDetailBody .hero .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0.5;
    color: #000000; }
  .ArticleDetailBody .author {
    color: gray;
    float: right;
    font-size: 12px;
    font-style: italic;
    font-weight: 300; }
    .ArticleDetailBody .author span {
      font-style: italic; }
  .ArticleDetailBody article {
    margin-left: 16px;
    margin-right: 16px; }
    .ArticleDetailBody article img {
      max-width: 100%;
      margin: auto;
      display: block; }
    .ArticleDetailBody article h1 {
      font-size: 21px;
      padding-top: 20px; }
    .ArticleDetailBody article h2 {
      font-size: 19px;
      padding-top: 15px; }
    .ArticleDetailBody article h3 {
      font-size: 17px;
      padding-top: 10px; }
    .ArticleDetailBody article ul li {
      list-style-type: square;
      margin-bottom: 0.5em; }
  .ArticleDetailBody .arrow-left {
    height: 10px;
    width: 16px !important; }
