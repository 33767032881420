/*
Less me
*/
a {
  text-decoration: none; }

.app-bar {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  height: 64px;
  position: fixed;
  top: 0;
  width: 100%; }
  @media (max-width: 1000px) {
    .app-bar {
      box-shadow: 0 1px 1px 0;
      background-color: #ffffff; } }

.app-bar-light {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  height: 64px;
  position: fixed;
  top: 0;
  width: 100%; }
  @media (max-width: 1000px) {
    .app-bar-light {
      box-shadow: 0 1px 1px 0;
      background-color: #ffffff; } }

.app-bar-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: white;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 50;
  padding: 0 15px; }

.headerBackground {
  display: block;
  background-color: #ffffff;
  width: 100%;
  height: 64px; }
  @media (min-width: 1000px) {
    .headerBackground {
      display: none; } }

.headerBackgroundLight {
  display: block;
  background-color: #ffffff;
  width: 100%;
  height: 64px; }
  @media (min-width: 1000px) {
    .headerBackgroundLight {
      display: none; } }

.logo-centered {
  align-items: center;
  justify-content: center; }

.AppHeader .app-bar-buttons .app-bar-selectors.top-menu.Selected {
  border-bottom: 3px solid; }
  .irc .AppHeader .app-bar-buttons .app-bar-selectors.top-menu.Selected {
    border-bottom-color: #FCD735; }
  .nrc .AppHeader .app-bar-buttons .app-bar-selectors.top-menu.Selected {
    border-bottom-color: #000000; }
  .generic .AppHeader .app-bar-buttons .app-bar-selectors.top-menu.Selected {
    border-bottom-color: #339933; }
  .mc .AppHeader .app-bar-buttons .app-bar-selectors.top-menu.Selected {
    border-bottom-color: #000000; }
  .pg .AppHeader .app-bar-buttons .app-bar-selectors.top-menu.Selected {
    border-bottom-color: #71D1A7; }
  .cuenta .AppHeader .app-bar-buttons .app-bar-selectors.top-menu.Selected {
    border-bottom-color: #339933; }
  .ri .AppHeader .app-bar-buttons .app-bar-selectors.top-menu.Selected {
    border-bottom-color: #005377; }

.selectors {
  align-items: center;
  display: flex;
  padding: 0;
  height: 36px;
  border-radius: 2px; }
  .selectors span {
    cursor: pointer;
    padding: 0 10px; }
  .selectors .country {
    align-items: center;
    display: inherit; }
    .selectors .country img {
      border: 1px solid #333;
      border-radius: 2px;
      width: 28px; }
      .selectors .country img:hover {
        border: 1px solid white; }
  .selectors .lang {
    font-size: 14px;
    font-weight: lighter;
    text-transform: uppercase;
    font-weight: bold; }
    .selectors .lang:hover {
      text-decoration: underline; }

.app-bar-buttons {
  color: #000000;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row; }
  .app-bar-buttons .app-bar-selectors {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    margin: 16px 5px; }
    @media (max-width: 320px) {
      .app-bar-buttons .app-bar-selectors {
        font-size: 10px; } }
    @media (min-width: 321px) {
      .app-bar-buttons .app-bar-selectors {
        font-size: 12px;
        margin: 16px 8px; } }
    @media (min-width: 960px) {
      .app-bar-buttons .app-bar-selectors {
        font-size: 14px;
        font-weight: 600;
        margin: 8px 30px 0; } }
    .app-bar-buttons .app-bar-selectors.top-menu {
      border-bottom: 3px solid;
      border-bottom-color: transparent;
      padding-bottom: 1%; }
      .app-bar-buttons .app-bar-selectors.top-menu .menu-item {
        font-weight: bold;
        white-space: pre; }
        .irc .app-bar-buttons .app-bar-selectors.top-menu .menu-item {
          color: #16697A; }
        .nrc .app-bar-buttons .app-bar-selectors.top-menu .menu-item {
          color: #000000; }
        .generic .app-bar-buttons .app-bar-selectors.top-menu .menu-item {
          color: #3D3FDA; }
        .mc .app-bar-buttons .app-bar-selectors.top-menu .menu-item {
          color: #000000; }
        .pg .app-bar-buttons .app-bar-selectors.top-menu .menu-item {
          color: #000000; }
        .cuenta .app-bar-buttons .app-bar-selectors.top-menu .menu-item {
          color: #3D3FDA; }
        .ri .app-bar-buttons .app-bar-selectors.top-menu .menu-item {
          color: #005377; }
        .app-bar-buttons .app-bar-selectors.top-menu .menu-item:hover {
          text-decoration: underline; }
      .app-bar-buttons .app-bar-selectors.top-menu.Selected {
        font-size: 15px; }
        .irc .app-bar-buttons .app-bar-selectors.top-menu.Selected {
          border-bottom-color: #FCD735; }
        .nrc .app-bar-buttons .app-bar-selectors.top-menu.Selected {
          border-bottom-color: #000000; }
        .generic .app-bar-buttons .app-bar-selectors.top-menu.Selected {
          border-bottom-color: #339933; }
        .mc .app-bar-buttons .app-bar-selectors.top-menu.Selected {
          border-bottom-color: #000000; }
        .pg .app-bar-buttons .app-bar-selectors.top-menu.Selected {
          border-bottom-color: #71D1A7; }
        .cuenta .app-bar-buttons .app-bar-selectors.top-menu.Selected {
          border-bottom-color: #339933; }
        .ri .app-bar-buttons .app-bar-selectors.top-menu.Selected {
          border-bottom-color: #005377; }
    .app-bar-buttons .app-bar-selectors svg {
      font-size: 24px; }
    .app-bar-buttons .app-bar-selectors.rtl svg {
      margin-left: 10px; }
    .app-bar-buttons .app-bar-selectors.ltr svg {
      margin-right: 10px; }

@-moz-document url-prefix() {
  .app-bar-buttons .search-button {
    padding-bottom: 40px !important; } }

@media (max-width: 1000px) {
  .top-menu {
    display: none !important; } }

.app-bar-separator {
  border-left-width: 1px;
  border-left-color: #ccc;
  border-left-style: solid;
  height: 25px; }

.fa-times {
  padding: 0 6px 0 6px; }

.AppHeader .app-bar-logo {
  height: 42px;
  cursor: pointer;
  width: auto; }

.AppHeaderLight .app-bar-logo {
  height: 42px;
  cursor: pointer;
  width: auto; }

.AppHeaderLight .country img {
  border: 1px solid #999; }
  .AppHeaderLight .country img:hover {
    border: 1px solid #555; }

.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 0px 1px 8px -5px;
  z-index: 3; }

.headroom--top {
  background-color: #ffffff; }

.headroom--unfixed {
  position: relative;
  -ms-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -webkit-transform: translateY(0%);
  transform: translateY(0); }

.headroom--scrolled, .headroom--unpinned, .headroom--pinned {
  -moz-transition: transform 200ms ease-in-out;
  -webkit-transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out; }

.headroom--unpinned {
  position: fixed;
  -ms-transform: translateY(-65px);
  -moz-transform: translateY(-65px);
  -webkit-transform: translateY(-65px);
  transform: translateY(-65px); }

.header-opacity {
  background-color: #ffffff; }

.headroom--pinned {
  position: fixed;
  -ms-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -webkit-transform: translateY(0%);
  transform: translateY(0%); }

.AppHeaderLight .headroom {
  background-color: #ffffff;
  box-shadow: 0px 1px 8px -5px;
  z-index: 3; }

.AppHeaderLight .headroom--top {
  background-color: #ffffff; }

.AppHeaderLight .app-bar-buttons {
  color: #000000; }

.AppHeader .search-bar-container {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  z-index: 1; }
  .AppHeader .search-bar-container .SearchBar {
    background-color: #ffffff;
    height: 30px;
    padding: 16px 16px 8px 16px;
    position: relative;
    width: 100%; }
    @media (min-width: 1000px) {
      .AppHeader .search-bar-container .SearchBar {
        max-width: 1024px;
        z-index: 1; }
        .AppHeader .search-bar-container .SearchBar input {
          color: #000000; }
          .AppHeader .search-bar-container .SearchBar input::placeholder {
            color: #000000; } }
    .AppHeader .search-bar-container .SearchBar input {
      -webkit-appearance: none;
      line-height: 25px;
      font-size: 18px;
      width: 100%;
      box-sizing: border-box;
      border: 0;
      background-color: inherit;
      border-bottom: 1px #ddd solid;
      color: #333;
      padding: 0; }
    .AppHeader .search-bar-container .SearchBar input:focus {
      outline: none; }
    .AppHeader .search-bar-container .SearchBar .fa-times-circle {
      margin-inline-start: -33px;
      margin: 5px 5px;
      top: 13px; }
      .ltr .AppHeader .search-bar-container .SearchBar .fa-times-circle {
        right: 55px; }
      .rtl .AppHeader .search-bar-container .SearchBar .fa-times-circle {
        left: 55px; }
    .AppHeader .search-bar-container .SearchBar .fa-search {
      margin: 5px 5px;
      padding-left: 8px;
      cursor: pointer; }
      @media (min-width: 1000px) {
        .AppHeader .search-bar-container .SearchBar .fa-search {
          top: 13px; } }
      @media (max-width: 1000px) {
        .AppHeader .search-bar-container .SearchBar .fa-search {
          top: 18px; } }
      .ltr .AppHeader .search-bar-container .SearchBar .fa-search {
        right: 5px; }
        @media (min-width: 1000px) {
          .ltr .AppHeader .search-bar-container .SearchBar .fa-search {
            right: 30px; } }
      .rtl .AppHeader .search-bar-container .SearchBar .fa-search {
        left: 5px; }
        @media (min-width: 1000px) {
          .rtl .AppHeader .search-bar-container .SearchBar .fa-search {
            left: 30px; } }
    .AppHeader .search-bar-container .SearchBar .fa {
      color: #000000;
      width: 25px;
      position: absolute; }
      @media (min-width: 1000px) {
        .AppHeader .search-bar-container .SearchBar .fa {
          font-size: 20px; } }
      @media (max-width: 1000px) {
        .AppHeader .search-bar-container .SearchBar .fa {
          font-size: 15px; } }

.AppHeaderLight .search-bar-container {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  z-index: 1; }
  .AppHeaderLight .search-bar-container .SearchBar {
    background-color: white;
    height: 30px;
    padding: 8px 16px;
    position: relative;
    width: 100%; }
    @media (min-width: 1000px) {
      .AppHeaderLight .search-bar-container .SearchBar {
        max-width: 1024px;
        z-index: 1; } }
    .AppHeaderLight .search-bar-container .SearchBar input {
      -webkit-appearance: none;
      line-height: 25px;
      font-size: 18px;
      width: 100%;
      box-sizing: border-box;
      border: 0;
      background-color: inherit;
      border-bottom: 1px #ddd solid;
      color: black;
      padding: 0; }
    .AppHeaderLight .search-bar-container .SearchBar input:focus {
      outline: none; }
    .AppHeaderLight .search-bar-container .SearchBar .fa-times-circle {
      margin-inline-start: -33px;
      margin: 5px 5px;
      top: 2px; }
      .ltr .AppHeaderLight .search-bar-container .SearchBar .fa-times-circle {
        right: 55px; }
      .rtl .AppHeaderLight .search-bar-container .SearchBar .fa-times-circle {
        left: 55px; }
    .AppHeaderLight .search-bar-container .SearchBar .fa-search {
      margin: 5px 5px;
      padding-left: 8px;
      top: 2px;
      cursor: pointer; }
      @media (min-width: 1000px) {
        .AppHeaderLight .search-bar-container .SearchBar .fa-search {
          top: 13px; } }
      @media (max-width: 1000px) {
        .AppHeaderLight .search-bar-container .SearchBar .fa-search {
          top: 16px; } }
      .ltr .AppHeaderLight .search-bar-container .SearchBar .fa-search {
        right: 5px; }
        @media (min-width: 1000px) {
          .ltr .AppHeaderLight .search-bar-container .SearchBar .fa-search {
            right: 30px; } }
      .rtl .AppHeaderLight .search-bar-container .SearchBar .fa-search {
        left: 5px; }
        @media (min-width: 1000px) {
          .rtl .AppHeaderLight .search-bar-container .SearchBar .fa-search {
            left: 30px; } }
    .AppHeaderLight .search-bar-container .SearchBar .fa {
      color: #242424;
      width: 25px;
      position: absolute; }
      @media (min-width: 1000px) {
        .AppHeaderLight .search-bar-container .SearchBar .fa {
          font-size: 20px; } }
      @media (max-width: 1000px) {
        .AppHeaderLight .search-bar-container .SearchBar .fa {
          font-size: 15px; } }

.app-bar-black {
  background-color: #000000; }

.AppHeader,
.AppHeaderLight {
  margin-top: 64px; }
  .AppHeader .privacy-banner,
  .AppHeaderLight .privacy-banner {
    align-items: center;
    background-color: black;
    bottom: 0;
    color: white;
    display: flex;
    flex: 1;
    font-size: 14px;
    font-weight: 300;
    flex-direction: row;
    justify-content: space-between;
    left: 0;
    right: 0;
    opacity: 0.85;
    padding: 16px;
    padding-bottom: 1em;
    position: fixed;
    z-index: 2; }
    .AppHeader .privacy-banner a,
    .AppHeaderLight .privacy-banner a {
      color: white;
      text-decoration: underline; }
    .AppHeader .privacy-banner span,
    .AppHeaderLight .privacy-banner span {
      padding: 4px; }
    .AppHeader .privacy-banner .content,
    .AppHeaderLight .privacy-banner .content {
      display: flex; }
    .AppHeader .privacy-banner .privacy-banner-separator,
    .AppHeaderLight .privacy-banner .privacy-banner-separator {
      border-left-width: 1px;
      border-left-color: #ffda1a;
      border-left-style: solid;
      height: auto;
      margin-left: 7px; }

.hidden {
  display: none; }

.search-close > span:first-of-type {
  position: relative;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: 20px;
  height: 20px;
  cursor: pointer;
  margin-top: 22px; }
  .rtl .search-close > span:first-of-type {
    left: 0; }

.search-close > span:first-of-type:before,
.search-close > span:first-of-type:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border: 2px solid #ffffff; }

.search-close > span:first-of-type:before {
  width: 75%;
  height: 75%;
  border-radius: 50%;
  border-width: 2px;
  transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  /* FF3.5/3.6 */
  -o-transform: rotate(45deg);
  /* Opera 10.5 */
  -webkit-transform: rotate(45deg);
  /* Saf3.1+ */
  transition: all 0.3s, background-color 0s linear 0s;
  filter: progid:DXImageTransform.Microsoft.Matrix(sizingMethod="auto expand", M11=0.7071067811865476, M12=-0.7071067811865475, M21=0.7071067811865475, M22=0.7071067811865476);
  /* IE6,IE7 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(SizingMethod='auto expand', M11=0.7071067811865476, M12=-0.7071067811865475, M21=0.7071067811865475, M22=0.7071067811865476)";
  /* IE8 */ }

.search-close > span:first-of-type:after {
  border-width: 1px;
  width: 0;
  height: 35%;
  left: 75%;
  top: 75%;
  margin-left: 0;
  margin-top: 0;
  background-color: #ffffff;
  transform: translate(-50%, -50%) rotate(-45deg);
  transition: all 0.3s; }

.active > span:first-of-type:before {
  width: 0;
  height: 100%;
  border-radius: 0;
  border-width: 1px;
  left: 50%;
  background-color: #ffffff;
  transition: all 0.3s, background-color 0s linear 0.3s;
  transform: translateX(-1px) rotate(45deg); }

.active > span:first-of-type:after {
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg); }

.MuiIconButton-label-30 > i,
.MuiSvgIcon-root-102 {
  display: none; }

.search-btn {
  cursor: pointer;
  opacity: 0.6;
  padding: 2px;
  margin-left: 18px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1000px) {
    .search-btn {
      width: 26px !important;
      height: 26px !important;
      font-size: 27px !important; } }
  @media (max-width: 1000px) {
    .search-btn {
      width: 16px !important;
      height: 16px !important;
      font-size: 20px !important; } }
  .search-btn:hover {
    opacity: 1; }
  .rtl .search-btn {
    padding-right: 18px; }

.SearchButton {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: 20px;
  height: 20px;
  cursor: pointer;
  margin-top: 24px;
  margin-left: 20px; }

.SearchButton:before,
.SearchButton:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border: 2px solid #ffffff; }

.SearchButton:before {
  width: 75%;
  height: 75%;
  border-radius: 50%;
  border-width: 2px;
  transform: rotate(45deg);
  transition: all 0.3s, background-color 0s linear 0s; }

.active .SearchButton:before {
  width: 0;
  height: 100%;
  border-radius: 0;
  border-width: 1px;
  left: 50%;
  background-color: #ffffff;
  transition: all 0.3s, background-color 0s linear 0.3s;
  transform: translateX(-1px) rotate(45deg); }

.SearchButton:after {
  border-width: 1px;
  width: 0;
  height: 35%;
  left: 75%;
  top: 75%;
  margin-left: 0;
  margin-top: 0;
  background-color: #ffffff;
  transform: translate(-50%, -50%) rotate(-45deg);
  transition: all 0.3s; }

.active .SearchButton:after {
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg); }

.SearchButton svg {
  display: none; }

.fa-times:before {
  margin-left: 3px; }

.search-bar-inner-icon {
  position: absolute; }

.close-alert {
  height: 24px;
  width: 24px !important; }
