a {
  text-decoration: none; }

.ArticleListOOD {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px; }
  .ArticleListOOD .Article,
  .ArticleListOOD .Service {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 16px;
    min-width: 100%; }
    .ArticleListOOD .Article .Image,
    .ArticleListOOD .Service .Image {
      width: 6rem;
      height: 4rem;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      align-self: center; }
      .ltr .ArticleListOOD .Article .Image, .ltr
      .ArticleListOOD .Service .Image {
        margin-right: 16px; }
      .rtl .ArticleListOOD .Article .Image, .rtl
      .ArticleListOOD .Service .Image {
        margin-left: 16px; }
      .ArticleListOOD .Article .Image img,
      .ArticleListOOD .Service .Image img {
        width: auto; }
    .ArticleListOOD .Article .Text,
    .ArticleListOOD .Service .Text {
      flex: 1;
      display: block;
      min-width: 100%; }
    .ArticleListOOD .Article .TextWithImage,
    .ArticleListOOD .Service .TextWithImage {
      flex: 1;
      min-width: 1% !important;
      display: block;
      min-width: 100%; }
    .ArticleListOOD .Article .Read-More,
    .ArticleListOOD .Service .Read-More {
      padding-top: 10px;
      flex-basis: 100%; }
  .ArticleListOOD hr {
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    height: 0;
    color: #000000;
    border-color: #000000; }
    .ltr .ArticleListOOD hr {
      margin-left: 0; }
    .rtl .ArticleListOOD hr {
      margin-right: 0; }
    .irc .ArticleListOOD hr {
      color: #ffda1a; }
    .nrc .ArticleListOOD hr {
      color: #ff7602; }
    .generic .ArticleListOOD hr {
      color: #29991f; }
    .mc .ArticleListOOD hr {
      color: #e60000; }
    .pg .ArticleListOOD hr {
      color: #59c2ed; }
    .cuenta .ArticleListOOD hr {
      color: #29991f; }
    .ri .ArticleListOOD hr {
      color: #cf5c36; }
    .irc .ArticleListOOD hr {
      border-color: #ffda1a; }
    .nrc .ArticleListOOD hr {
      border-color: #ff7602; }
    .generic .ArticleListOOD hr {
      border-color: #29991f; }
    .mc .ArticleListOOD hr {
      border-color: #e60000; }
    .pg .ArticleListOOD hr {
      border-color: #59c2ed; }
    .cuenta .ArticleListOOD hr {
      border-color: #29991f; }
    .ri .ArticleListOOD hr {
      border-color: #cf5c36; }
  .ArticleListOOD hr.line {
    padding: 0;
    margin: auto;
    border: 0;
    border-top-width: 1px;
    border-top-color: #bdbdbd;
    border-style: solid;
    border-radius: 1px;
    height: 0;
    width: 100%; }
  .ArticleListOOD .Title {
    width: 100%;
    display: flex;
    margin: 0;
    background-color: #f0f0f0;
    min-height: 60px;
    flex-direction: row;
    align-items: center; }
  .ArticleListOOD .Title > h1 {
    font-size: 18px;
    display: block;
    width: 100%;
    margin: 0;
    padding: 10px;
    flex: 1; }
  .ArticleListOOD .Title > h1 > small {
    font-size: 12px;
    display: block;
    width: 100%;
    color: gray; }
  .ArticleListOOD .list {
    display: flex;
    margin-left: 16px;
    margin-right: 16px;
    flex: 1;
    flex-direction: column; }
    .ArticleListOOD .list img {
      margin: auto;
      display: block; }
    .ArticleListOOD .list p {
      margin: 0; }
    .ArticleListOOD .list h1 {
      font-size: 24px;
      margin-bottom: 0; }
      .ltr .ArticleListOOD .list h1 .fa {
        margin-right: 8px; }
      .rtl .ArticleListOOD .list h1 .fa {
        margin-left: 8px; }
    .ArticleListOOD .list h2 {
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 0; }
      .ArticleListOOD .list h2 small {
        display: block; }
    .ArticleListOOD .list h3 {
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 0; }
      .ArticleListOOD .list h3 small {
        display: block; }
    .ArticleListOOD .list ul li {
      list-style-type: square; }
