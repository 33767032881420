a {
  text-decoration: none; }

.irc ::selection {
  background-color: #ffda1a; }

.nrc ::selection {
  background-color: #ff7602; }

.generic ::selection {
  background-color: #29991f; }

.mc ::selection {
  background-color: #e60000; }

.pg ::selection {
  background-color: #59c2ed; }

.cuenta ::selection {
  background-color: #29991f; }

.ri ::selection {
  background-color: #cf5c36; }

.irc ::selection {
  color: #000000; }

.nrc ::selection {
  color: #ffffff; }

.generic ::selection {
  color: #ffffff; }

.mc ::selection {
  color: #ffffff; }

.pg ::selection {
  color: #ffffff; }

.cuenta ::selection {
  color: #ffffff; }

.ri ::selection {
  color: #ffffff; }

.irc ::-moz-selection {
  background-color: #ffda1a; }

.nrc ::-moz-selection {
  background-color: #ff7602; }

.generic ::-moz-selection {
  background-color: #29991f; }

.mc ::-moz-selection {
  background-color: #e60000; }

.pg ::-moz-selection {
  background-color: #59c2ed; }

.cuenta ::-moz-selection {
  background-color: #29991f; }

.ri ::-moz-selection {
  background-color: #cf5c36; }

.irc ::-moz-selection {
  color: #000000; }

.nrc ::-moz-selection {
  color: #ffffff; }

.generic ::-moz-selection {
  color: #ffffff; }

.mc ::-moz-selection {
  color: #ffffff; }

.pg ::-moz-selection {
  color: #ffffff; }

.cuenta ::-moz-selection {
  color: #ffffff; }

.ri ::-moz-selection {
  color: #ffffff; }

.green {
  color: green; }

.red {
  color: red; }

.yellow {
  color: yellow; }

.blue {
  color: yellow; }

.LoaderContainer {
  display: flex;
  min-height: calc(100vh); }

.loader {
  font-size: 10px;
  margin: 50px auto;
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  content: " "; }
  .irc .loader {
    background: #ffda1a;
    background: -moz-linear-gradient(left, #ffda1a 10%, white 42%);
    background: -webkit-linear-gradient(left, #ffda1a 10%, swhite 42%);
    background: -o-linear-gradient(left, #ffda1a 10%, white 42%);
    background: -ms-linear-gradient(left, #ffda1a 10%, white 42%);
    background: linear-gradient(to right, #ffda1a 10%, white 42%); }
  .nrc .loader {
    background: #ff7602;
    background: -moz-linear-gradient(left, #ff7602 10%, white 42%);
    background: -webkit-linear-gradient(left, #ff7602 10%, swhite 42%);
    background: -o-linear-gradient(left, #ff7602 10%, white 42%);
    background: -ms-linear-gradient(left, #ff7602 10%, white 42%);
    background: linear-gradient(to right, #ff7602 10%, white 42%); }
  .generic .loader {
    background: #29991f;
    background: -moz-linear-gradient(left, #29991f 10%, white 42%);
    background: -webkit-linear-gradient(left, #29991f 10%, swhite 42%);
    background: -o-linear-gradient(left, #29991f 10%, white 42%);
    background: -ms-linear-gradient(left, #29991f 10%, white 42%);
    background: linear-gradient(to right, #29991f 10%, white 42%); }
  .mc .loader {
    background: #e60000;
    background: -moz-linear-gradient(left, #e60000 10%, white 42%);
    background: -webkit-linear-gradient(left, #e60000 10%, swhite 42%);
    background: -o-linear-gradient(left, #e60000 10%, white 42%);
    background: -ms-linear-gradient(left, #e60000 10%, white 42%);
    background: linear-gradient(to right, #e60000 10%, white 42%); }
  .pg .loader {
    background: #59c2ed;
    background: -moz-linear-gradient(left, #59c2ed 10%, white 42%);
    background: -webkit-linear-gradient(left, #59c2ed 10%, swhite 42%);
    background: -o-linear-gradient(left, #59c2ed 10%, white 42%);
    background: -ms-linear-gradient(left, #59c2ed 10%, white 42%);
    background: linear-gradient(to right, #59c2ed 10%, white 42%); }
  .cuenta .loader {
    background: #29991f;
    background: -moz-linear-gradient(left, #29991f 10%, white 42%);
    background: -webkit-linear-gradient(left, #29991f 10%, swhite 42%);
    background: -o-linear-gradient(left, #29991f 10%, white 42%);
    background: -ms-linear-gradient(left, #29991f 10%, white 42%);
    background: linear-gradient(to right, #29991f 10%, white 42%); }
  .ri .loader {
    background: #cf5c36;
    background: -moz-linear-gradient(left, #cf5c36 10%, white 42%);
    background: -webkit-linear-gradient(left, #cf5c36 10%, swhite 42%);
    background: -o-linear-gradient(left, #cf5c36 10%, white 42%);
    background: -ms-linear-gradient(left, #cf5c36 10%, white 42%);
    background: linear-gradient(to right, #cf5c36 10%, white 42%); }

.loader:before {
  width: 50%;
  height: 50%;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: ""; }
  .irc .loader:before {
    background: #ffda1a; }
  .nrc .loader:before {
    background: #ff7602; }
  .generic .loader:before {
    background: #29991f; }
  .mc .loader:before {
    background: #e60000; }
  .pg .loader:before {
    background: #59c2ed; }
  .cuenta .loader:before {
    background: #29991f; }
  .ri .loader:before {
    background: #cf5c36; }

.loader:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.rtl i.material-icons, .rtl
i.icon, .rtl
i.fa {
  /*
		-moz-transform: scaleX(-1);
		-o-transform: scaleX(-1);
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);
		filter: FlipH;
		-ms-filter: "FlipH";
		*/ }

credit:before {
  content: " ";
  padding-right: 5px;
  padding-left: 5px; }

credit::after {
  content: " ";
  padding-right: 5px;
  padding-left: 5px; }

credit {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  position: relative;
  width: 100%;
  margin-top: -30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  line-height: 30px; }

imagelabel {
  display: block;
  width: 100%;
  overflow: hidden;
  text-align: center;
  font-style: italic; }

btn > a,
btn-muted > a,
btnmuted > a {
  display: flex;
  text-transform: capitalize;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 44px;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: bold;
  line-height: 20px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 3px;
  padding-top: 3px; }

.irc btn > a {
  background-color: #ffda1a; }

.nrc btn > a {
  background-color: #ff7602; }

.generic btn > a {
  background-color: #29991f; }

.mc btn > a {
  background-color: #e60000; }

.pg btn > a {
  background-color: #59c2ed; }

.cuenta btn > a {
  background-color: #29991f; }

.ri btn > a {
  background-color: #cf5c36; }

.irc btn > a {
  color: #000000; }

.nrc btn > a {
  color: #ffffff; }

.generic btn > a {
  color: #ffffff; }

.mc btn > a {
  color: #ffffff; }

.pg btn > a {
  color: #ffffff; }

.cuenta btn > a {
  color: #ffffff; }

.ri btn > a {
  color: #ffffff; }

btn > a:active {
  border: 1px black solid; }
  .irc btn > a:active {
    border-color: #4d4100; }
  .nrc btn > a:active {
    border-color: #351800; }
  .generic btn > a:active {
    border-color: black; }
  .mc btn > a:active {
    border-color: #1a0000; }
  .pg btn > a:active {
    border-color: #0c526e; }
  .cuenta btn > a:active {
    border-color: black; }
  .ri btn > a:active {
    border-color: #2e140b; }
  .irc btn > a:active {
    background-color: #e6c100; }
  .nrc btn > a:active {
    background-color: #ce5e00; }
  .generic btn > a:active {
    background-color: #1e6f16; }
  .mc btn > a:active {
    background-color: #b30000; }
  .pg btn > a:active {
    background-color: #2bb1e8; }
  .cuenta btn > a:active {
    background-color: #1e6f16; }
  .ri btn > a:active {
    background-color: #aa4928; }

btn > a:hover {
  border: 0; }

btn > a {
  font-size: 100%;
  text-decoration: none;
  border: 0; }
  .irc btn > a {
    color: #000000; }
  .nrc btn > a {
    color: #ffffff; }
  .generic btn > a {
    color: #ffffff; }
  .mc btn > a {
    color: #ffffff; }
  .pg btn > a {
    color: #ffffff; }
  .cuenta btn > a {
    color: #ffffff; }
  .ri btn > a {
    color: #ffffff; }

btn-muted > a,
btn.muted > a,
btnmuted > a {
  background-color: #f2f2f2;
  color: #333333;
  font-size: 100%;
  text-decoration: none;
  border: 0; }
  btn-muted > a:active,
  btn.muted > a:active,
  btnmuted > a:active {
    background-color: #717171; }
  btn-muted > a:hover,
  btn.muted > a:hover,
  btnmuted > a:hover {
    border: 0; }

.tel {
  direction: ltr;
  display: inline-block; }

.rtl a {
  text-decoration: none;
  line-height: 100%; }

.rtl s > a {
  border-bottom: none; }
