a {
  text-decoration: none; }

.InstanceMovedWidget {
  background-color: white;
  padding: 50px 20px;
  text-align: center; }
  @media (min-width: 1000px) {
    .InstanceMovedWidget {
      padding: 90px 20% 50px; }
      .InstanceMovedWidget h1 {
        font-size: 30px; }
      .InstanceMovedWidget .buttons {
        display: flex;
        justify-content: center; }
        .InstanceMovedWidget .buttons a {
          margin: 0 10px; } }
  .InstanceMovedWidget span {
    background-size: contain;
    display: block;
    margin: 0 auto 50px;
    width: 100px;
    height: 100px; }
  .InstanceMovedWidget a, .InstanceMovedWidget .rtl a {
    border-bottom: none !important;
    border-radius: 5px;
    display: block;
    font-weight: bold;
    height: 70px;
    line-height: 70px !important;
    margin: 0 auto 10px;
    width: 250px;
    outline: none;
    padding: 5px;
    text-decoration: none; }
    .irc .InstanceMovedWidget a, .irc .InstanceMovedWidget .rtl a {
      background-color: #e6c100; }
    .nrc .InstanceMovedWidget a, .nrc .InstanceMovedWidget .rtl a {
      background-color: #ce5e00; }
    .generic .InstanceMovedWidget a, .generic .InstanceMovedWidget .rtl a {
      background-color: #1e6f16; }
    .mc .InstanceMovedWidget a, .mc .InstanceMovedWidget .rtl a {
      background-color: #b30000; }
    .pg .InstanceMovedWidget a, .pg .InstanceMovedWidget .rtl a {
      background-color: #2bb1e8; }
    .cuenta .InstanceMovedWidget a, .cuenta .InstanceMovedWidget .rtl a {
      background-color: #1e6f16; }
    .ri .InstanceMovedWidget a, .ri .InstanceMovedWidget .rtl a {
      background-color: #aa4928; }
    .irc .InstanceMovedWidget a, .irc .InstanceMovedWidget .rtl a {
      color: #000000; }
    .nrc .InstanceMovedWidget a, .nrc .InstanceMovedWidget .rtl a {
      color: #ffffff; }
    .generic .InstanceMovedWidget a, .generic .InstanceMovedWidget .rtl a {
      color: #ffffff; }
    .mc .InstanceMovedWidget a, .mc .InstanceMovedWidget .rtl a {
      color: #ffffff; }
    .pg .InstanceMovedWidget a, .pg .InstanceMovedWidget .rtl a {
      color: #ffffff; }
    .cuenta .InstanceMovedWidget a, .cuenta .InstanceMovedWidget .rtl a {
      color: #ffffff; }
    .ri .InstanceMovedWidget a, .ri .InstanceMovedWidget .rtl a {
      color: #ffffff; }
    .InstanceMovedWidget a:hover, .InstanceMovedWidget .rtl a:hover {
      text-decoration: underline; }
      .irc .InstanceMovedWidget a:hover, .irc .InstanceMovedWidget .rtl a:hover {
        background-color: #ffda1a; }
      .nrc .InstanceMovedWidget a:hover, .nrc .InstanceMovedWidget .rtl a:hover {
        background-color: #ff7602; }
      .generic .InstanceMovedWidget a:hover, .generic .InstanceMovedWidget .rtl a:hover {
        background-color: #29991f; }
      .mc .InstanceMovedWidget a:hover, .mc .InstanceMovedWidget .rtl a:hover {
        background-color: #e60000; }
      .pg .InstanceMovedWidget a:hover, .pg .InstanceMovedWidget .rtl a:hover {
        background-color: #59c2ed; }
      .cuenta .InstanceMovedWidget a:hover, .cuenta .InstanceMovedWidget .rtl a:hover {
        background-color: #29991f; }
      .ri .InstanceMovedWidget a:hover, .ri .InstanceMovedWidget .rtl a:hover {
        background-color: #cf5c36; }
  .InstanceMovedWidget h1 {
    font-size: 25px;
    line-height: 150%;
    text-transform: uppercase; }
  .InstanceMovedWidget h3 {
    font-weight: normal; }
