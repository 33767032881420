a {
  text-decoration: none; }

.SearchPage {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 1024px;
  margin: 0 auto;
  padding-bottom: 10px; }
  .SearchPage .CategoryList {
    margin: unset; }
  .SearchPage .Article,
  .SearchPage .Service {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 16px; }
    .SearchPage .Article .Image,
    .SearchPage .Service .Image {
      width: 6rem;
      height: 4rem;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      align-self: center; }
      .ltr .SearchPage .Article .Image, .ltr
      .SearchPage .Service .Image {
        margin-right: 16px; }
      .rtl .SearchPage .Article .Image, .rtl
      .SearchPage .Service .Image {
        margin-left: 16px; }
      .SearchPage .Article .Image img,
      .SearchPage .Service .Image img {
        width: auto; }
    .SearchPage .Article .Text,
    .SearchPage .Service .Text {
      flex: 1;
      display: block;
      min-width: 100%; }
    .SearchPage .Article .TextWithImage,
    .SearchPage .Service .TextWithImage {
      flex: 1;
      min-width: 1% !important;
      display: block;
      min-width: 100%; }
    .SearchPage .Article .Read-More,
    .SearchPage .Service .Read-More {
      padding-top: 10px;
      flex-basis: 100%; }
  .SearchPage .no-results {
    margin: 0 10px; }
  .SearchPage hr {
    width: 100%;
    border: unset;
    border-top: 1px solid #ddd;
    height: 0; }
    .ltr .SearchPage hr {
      margin-left: 0; }
    .rtl .SearchPage hr {
      margin-right: 0; }
    .SearchPage hr.line {
      margin: 0 10px;
      width: auto; }
  .SearchPage .Title {
    width: 100%;
    display: flex;
    margin: 0;
    /*background-color: $titleBackground;*/
    min-height: 60px;
    flex-direction: row;
    align-items: center; }
  .SearchPage .Title > h1 {
    color: #666;
    display: block;
    flex: 1;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    padding: 10px;
    text-transform: uppercase;
    width: 100%; }
  .SearchPage .Title > h1 > small {
    font-size: 12px;
    display: block;
    width: 100%;
    color: gray; }
  .SearchPage .results {
    display: flex;
    flex: 1;
    flex-direction: column; }
    .SearchPage .results img {
      margin: auto;
      display: block; }
    .SearchPage .results p {
      margin: 0; }
    .SearchPage .results .services-list > h1,
    .SearchPage .results .articles-list > h1 {
      font-size: 20px;
      margin: 0 10px; }
      @media (min-width: 1000px) {
        .SearchPage .results .services-list > h1,
        .SearchPage .results .articles-list > h1 {
          font-size: 24px; } }
      .ltr .SearchPage .results .services-list > h1 .fa, .ltr
      .SearchPage .results .articles-list > h1 .fa {
        margin-right: 8px; }
      .rtl .SearchPage .results .services-list > h1 .fa, .rtl
      .SearchPage .results .articles-list > h1 .fa {
        margin-left: 8px; }
    .SearchPage .results h2 {
      font-size: 16px;
      font-weight: normal; }
      .SearchPage .results h2 small {
        display: block; }
    .SearchPage .results h3 {
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 0; }
      .SearchPage .results h3 small {
        display: block; }
    .SearchPage .results ul li {
      list-style-type: square; }
    .SearchPage .results .articles-list {
      margin-top: 20px; }
  .SearchPage .show-action {
    margin: 10px auto;
    text-align: center;
    width: 100%; }
    .SearchPage .show-action .show-more {
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 13px;
      height: 41px;
      padding: 0 20px;
      text-align: center;
      white-space: nowrap; }
      .irc .SearchPage .show-action .show-more {
        background-color: #ffda1a; }
      .nrc .SearchPage .show-action .show-more {
        background-color: #ff7602; }
      .generic .SearchPage .show-action .show-more {
        background-color: #29991f; }
      .mc .SearchPage .show-action .show-more {
        background-color: #e60000; }
      .pg .SearchPage .show-action .show-more {
        background-color: #59c2ed; }
      .cuenta .SearchPage .show-action .show-more {
        background-color: #29991f; }
      .ri .SearchPage .show-action .show-more {
        background-color: #cf5c36; }
      .irc .SearchPage .show-action .show-more {
        color: #000000; }
      .nrc .SearchPage .show-action .show-more {
        color: #ffffff; }
      .generic .SearchPage .show-action .show-more {
        color: #ffffff; }
      .mc .SearchPage .show-action .show-more {
        color: #ffffff; }
      .pg .SearchPage .show-action .show-more {
        color: #ffffff; }
      .cuenta .SearchPage .show-action .show-more {
        color: #ffffff; }
      .ri .SearchPage .show-action .show-more {
        color: #ffffff; }
      .SearchPage .show-action .show-more:hover {
        text-decoration: underline; }
        .irc .SearchPage .show-action .show-more:hover {
          background-color: #ffda1a; }
        .nrc .SearchPage .show-action .show-more:hover {
          background-color: #ff7602; }
        .generic .SearchPage .show-action .show-more:hover {
          background-color: #29991f; }
        .mc .SearchPage .show-action .show-more:hover {
          background-color: #e60000; }
        .pg .SearchPage .show-action .show-more:hover {
          background-color: #59c2ed; }
        .cuenta .SearchPage .show-action .show-more:hover {
          background-color: #29991f; }
        .ri .SearchPage .show-action .show-more:hover {
          background-color: #cf5c36; }
