a {
  text-decoration: none; }

.Footer #lang-icn {
  height: 24px; }

.irc .Footer .fill-clr {
  fill: #ffda1a; }

.nrc .Footer .fill-clr {
  fill: #ff7602; }

.generic .Footer .fill-clr {
  fill: #29991f; }

.mc .Footer .fill-clr {
  fill: #e60000; }

.pg .Footer .fill-clr {
  fill: #59c2ed; }

.cuenta .Footer .fill-clr {
  fill: #29991f; }

.ri .Footer .fill-clr {
  fill: #cf5c36; }

.Footer .cls-1 {
  fill: none; }

.Footer .cls-2 {
  clip-path: url(#clip-path); }

.Footer .cls-3 {
  clip-path: url(#clip-path-2); }

.Footer .cls-5 {
  clip-path: url(#clip-path-3); }

.Footer .Signpost a {
  color: black;
  opacity: 1; }
  .rtl .Footer .Signpost a {
    flex: 1; }

.rtl .Footer .Signpost {
  direction: rtl !important;
  flex-direction: row-reverse; }

.Footer .Signpost span {
  padding: 0 3px 0 3px; }
  .Footer .Signpost span:hover {
    color: #ccc !important; }

.Footer .light {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 100px;
  padding: 10px; }
  @media (max-width: 1000px) {
    .Footer .light {
      min-height: 140px;
      padding: 10px 10px 57px 10px;
      box-sizing: border-box;
      justify-content: space-between; }
      .Footer .light p {
        margin: unset !important;
        font-size: 10px !important; }
      .Footer .light .link-container svg {
        font-size: 0.9em; }
      .Footer .light .link-container h3 {
        font-size: 0.9em; } }
  .Footer .light h3 {
    margin: 10px; }
  .Footer .light p {
    margin: 0 20px;
    color: white;
    font-size: 14px; }
  .irc .Footer .light, .irc
  .Footer .light a {
    background-color: #7FB685; }
  .nrc .Footer .light, .nrc
  .Footer .light a {
    background-color: #000000; }
  .generic .Footer .light, .generic
  .Footer .light a {
    background-color: #3D3FDA; }
  .mc .Footer .light, .mc
  .Footer .light a {
    background-color: #000000; }
  .pg .Footer .light, .pg
  .Footer .light a {
    background-color: #000000; }
  .cuenta .Footer .light, .cuenta
  .Footer .light a {
    background-color: #3D3FDA; }
  .ri .Footer .light, .ri
  .Footer .light a {
    background-color: #005377; }
  .Footer .light .link-container a {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center; }
  .Footer .light .link-container svg {
    font-size: 1.2em; }

.Footer .dark {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  padding: 5px;
  background-color: #f8f8f8;
  color: #ffffff; }
  .Footer .dark img.app-store-logo {
    max-height: 50px;
    margin-top: 10px; }
  .Footer .dark span {
    align-items: baseline;
    color: #F5F5F5;
    font-size: 12px;
    letter-spacing: inherit;
    margin: 0.4rem;
    text-align: center; }
    .Footer .dark span.Signpost span {
      color: black; }
    .Footer .dark span.padded {
      padding: 2em 0 5em 0; }
    .Footer .dark span .administration-button {
      width: 328px;
      height: 11px;
      font-family: Helvetica;
      font-size: 9px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: black;
      color: var(--white); }

.Footer .button-container {
  border-bottom: 1px #222 solid;
  display: flex;
  flex-direction: row;
  height: 120px;
  width: 90%; }
  .rtl .Footer .button-container {
    flex-direction: row-reverse; }

.Footer .button {
  cursor: pointer;
  flex-grow: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center; }
  .Footer .button:hover span {
    text-decoration: underline; }
  .Footer .button.left {
    height: 100%;
    border: 0;
    border-right-color: #717171;
    border-right-width: 1px;
    border-right-style: solid; }
  .irc .Footer .button .icon-container {
    color: #ffda1a; }
  .nrc .Footer .button .icon-container {
    color: #ff7602; }
  .generic .Footer .button .icon-container {
    color: #29991f; }
  .mc .Footer .button .icon-container {
    color: #e60000; }
  .pg .Footer .button .icon-container {
    color: #59c2ed; }
  .cuenta .Footer .button .icon-container {
    color: #29991f; }
  .ri .Footer .button .icon-container {
    color: #cf5c36; }

@media (max-width: 1000px) {
  .Footer .button-desktop {
    display: none; } }
