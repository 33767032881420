a {
  text-decoration: none; }

.CountrySelector {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh); }
  .CountrySelector .bottom {
    height: 100px; }
  .CountrySelector .item {
    border: 0;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    color: #5e5e5e;
    font-size: 16px;
    height: 70px;
    letter-spacing: 0;
    line-height: 20px;
    margin: 5px auto;
    outline: none;
    padding: 5px;
    width: 100%; }
    @media (min-width: 800px) {
      .CountrySelector .item {
        border-radius: 5px;
        width: 300px; } }
    .CountrySelector .item:active {
      background-color: #717171; }
    .CountrySelector .item:hover {
      border-color: #ccc; }
    .CountrySelector .item[disabled] {
      background-color: #fefefe;
      color: #a4a4a4;
      border: 1px solid #8a8a8a; }
  .CountrySelector .text {
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0;
    position: relative; }
    .CountrySelector .text i {
      display: block;
      font-size: 48px; }
      .irc .CountrySelector .text i {
        color: #ffda1a; }
      .nrc .CountrySelector .text i {
        color: #ff7602; }
      .generic .CountrySelector .text i {
        color: #29991f; }
      .mc .CountrySelector .text i {
        color: #e60000; }
      .pg .CountrySelector .text i {
        color: #59c2ed; }
      .cuenta .CountrySelector .text i {
        color: #29991f; }
      .ri .CountrySelector .text i {
        color: #cf5c36; }
    .CountrySelector .text h1 {
      font-size: 18px;
      color: #000000;
      letter-spacing: 0;
      line-height: 20px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 0; }
    .CountrySelector .text h2 {
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      line-height: 20px;
      text-transform: uppercase;
      margin-bottom: 0;
      padding: 5px; }
  .CountrySelector .other {
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative; }
    .CountrySelector .other i {
      display: block;
      font-size: 48px; }
      .irc .CountrySelector .other i {
        color: #ffda1a; }
      .nrc .CountrySelector .other i {
        color: #ff7602; }
      .generic .CountrySelector .other i {
        color: #29991f; }
      .mc .CountrySelector .other i {
        color: #e60000; }
      .pg .CountrySelector .other i {
        color: #59c2ed; }
      .cuenta .CountrySelector .other i {
        color: #29991f; }
      .ri .CountrySelector .other i {
        color: #cf5c36; }
    .CountrySelector .other h1 {
      font-size: 18px;
      color: #000000;
      letter-spacing: 0;
      line-height: 20px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 0; }
  .CountrySelector .item {
    cursor: pointer; }
  .CountrySelector .item-language {
    color: #666;
    cursor: pointer;
    font-size: 15px;
    font-weight: lighter;
    margin: 17px auto;
    text-decoration: underline; }
    .CountrySelector .item-language:hover {
      color: black; }
