a {
  text-decoration: none; }

.Category h3 {
  text-transform: uppercase; }

.Category h3:hover {
  text-transform: uppercase; }

.Category .Article {
  font-size: 80%; }
  .Category .Article h3 {
    text-transform: none;
    margin-bottom: 3px;
    font-weight: 700; }
