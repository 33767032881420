a {
  text-decoration: none; }

body {
  background-color: black; }

@media (min-width: 1000px) {
  .app-bar-container {
    margin-left: 2vw;
    margin-right: 2vw; }
  footer {
    border-bottom-width: 0;
    border-top-width: 0;
    border-right-width: 0px;
    border-left-width: 0px;
    border-style: solid;
    border-color: #343434; }
  .SkeletonContainer {
    border-bottom-width: 0;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: 20px;
    border-style: solid;
    border-color: #343434; }
  .bg-gray {
    background-color: #f8f8f8; } }

@media (min-width: 1400px) {
  .app-bar-container {
    margin-left: 4vw;
    margin-right: 4vw; }
  footer {
    border-right-width: 0px;
    border-left-width: 0px; }
  .SkeletonContainer {
    border-right-width: 0;
    border-left-width: 0;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-bottom: 20px; } }

s,
square {
  text-decoration: none;
  align-items: baseline; }
  .ltr s, .ltr
  square {
    margin-bottom: auto;
    vertical-align: top; }
  s > a,
  square > a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold; }
  s.Read-More,
  square.Read-More {
    align-items: center;
    display: flex; }
    .irc s.Read-More, .irc
    square.Read-More {
      color: #e6c100; }
    .nrc s.Read-More, .nrc
    square.Read-More {
      color: #ce5e00; }
    .generic s.Read-More, .generic
    square.Read-More {
      color: #1e6f16; }
    .mc s.Read-More, .mc
    square.Read-More {
      color: #b30000; }
    .pg s.Read-More, .pg
    square.Read-More {
      color: #2bb1e8; }
    .cuenta s.Read-More, .cuenta
    square.Read-More {
      color: #1e6f16; }
    .ri s.Read-More, .ri
    square.Read-More {
      color: #aa4928; }
    s.Read-More a:hover,
    square.Read-More a:hover {
      text-decoration: underline; }
  .ltr s > a:before, .ltr
  square > a:before {
    padding-right: 5px; }
  .rtl s > a:before, .rtl
  square > a:before {
    padding-left: 5px; }

a, em, li {
  color: black; }

.Skeleton {
  background-color: #f8f8f8; }
  .rtl .Skeleton {
    direction: rtl; }
