a {
  text-decoration: none; }

.LanguageSelector {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh); }
  .LanguageSelector .bottom {
    height: 100px; }
  .LanguageSelector .item {
    border: 0;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    color: #5e5e5e;
    font-size: 16px;
    height: 70px;
    letter-spacing: 0;
    line-height: 20px;
    margin: 5px auto;
    outline: none;
    padding: 5px;
    width: 100%; }
    @media (min-width: 800px) {
      .LanguageSelector .item {
        border-radius: 5px;
        width: 300px; } }
    .LanguageSelector .item:active {
      background-color: #717171; }
    .LanguageSelector .item:hover {
      border-color: #ccc; }
    .LanguageSelector .item[disabled] {
      background-color: #fefefe;
      color: #a4a4a4;
      border: 1px solid #8a8a8a; }
  .LanguageSelector .text {
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0;
    position: relative; }
    .LanguageSelector .text i {
      display: block;
      font-size: 48px; }
      .irc .LanguageSelector .text i {
        color: #ffda1a; }
      .nrc .LanguageSelector .text i {
        color: #ff7602; }
      .generic .LanguageSelector .text i {
        color: #29991f; }
      .mc .LanguageSelector .text i {
        color: #e60000; }
      .pg .LanguageSelector .text i {
        color: #59c2ed; }
      .cuenta .LanguageSelector .text i {
        color: #29991f; }
      .ri .LanguageSelector .text i {
        color: #cf5c36; }
    .LanguageSelector .text h1 {
      font-size: 18px;
      color: #000000;
      letter-spacing: 0;
      line-height: 20px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 0; }
    .LanguageSelector .text h2 {
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      line-height: 20px;
      text-transform: uppercase;
      margin-bottom: 0;
      padding: 5px; }
  .LanguageSelector .other {
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative; }
    .LanguageSelector .other i {
      display: block;
      font-size: 48px; }
      .irc .LanguageSelector .other i {
        color: #ffda1a; }
      .nrc .LanguageSelector .other i {
        color: #ff7602; }
      .generic .LanguageSelector .other i {
        color: #29991f; }
      .mc .LanguageSelector .other i {
        color: #e60000; }
      .pg .LanguageSelector .other i {
        color: #59c2ed; }
      .cuenta .LanguageSelector .other i {
        color: #29991f; }
      .ri .LanguageSelector .other i {
        color: #cf5c36; }
    .LanguageSelector .other h1 {
      font-size: 18px;
      color: #000000;
      letter-spacing: 0;
      line-height: 20px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 0; }
  .LanguageSelector .icon {
    flex-grow: 1;
    max-height: 200px;
    min-height: 15px;
    padding: 20px; }
  .LanguageSelector .item {
    cursor: pointer; }

.p-t-20 {
  padding-top: 20px; }
