a {
  text-decoration: none; }

.HomeWidget {
  padding: 20px 20px; }
  .HomeWidget h1,
  .HomeWidget h3 {
    font-size: 18px;
    text-transform: uppercase; }
  .HomeWidget.Highlighted {
    padding: 0;
    margin-bottom: -3rem; }
  .HomeWidget .Article.Home h1 {
    font-size: 1.17em; }
  .HomeWidget .hero {
    max-height: 440px;
    overflow: hidden; }
  .HomeWidget a {
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    text-transform: none; }
    .irc .HomeWidget a {
      color: #292633; }
    .nrc .HomeWidget a {
      color: #000000; }
    .generic .HomeWidget a {
      color: #3D3FDA; }
    .mc .HomeWidget a {
      color: #000000; }
    .pg .HomeWidget a {
      color: #000000; }
    .cuenta .HomeWidget a {
      color: #3D3FDA; }
    .ri .HomeWidget a {
      color: #005377; }
    .HomeWidget a:hover {
      text-decoration: underline; }
  .HomeWidget .TopCategory:hover {
    text-decoration: none !important; }
  .HomeWidget.CT-article {
    background-color: #f2f2f2;
    border-top: 1px solid #ddd;
    margin: 40px auto 0; }
  @media (min-width: 1000px) {
    .HomeWidget {
      flex-basis: calc(50% - 40px);
      width: 100%; }
    .HomeWidget.Highlighted {
      flex-basis: 100%;
      max-width: unset;
      padding: 0; }
    .HomeWidget .hero {
      max-height: 485px; }
    .HomeWidget.CT-article {
      border: 1px solid #ddd;
      flex-basis: unset;
      margin-bottom: 30px;
      width: 100%;
      max-width: 1024px; }
      .HomeWidget.CT-article .Article.Home > h1 {
        margin-bottom: 25px; }
      .HomeWidget.CT-article .Article.Home .faq {
        column-count: 2;
        column-gap: 50px;
        margin-bottom: unset; } }
  .HomeWidget .TopCategories .read-more-container {
    display: flex;
    justify-content: center;
    align-items: center; }
    .HomeWidget .TopCategories .read-more-container .Read-More {
      text-decoration: underline; }
      .HomeWidget .TopCategories .read-more-container .Read-More a {
        font-size: 18px;
        font-weight: 900; }
  @media (max-width: 1000px) {
    .HomeWidget .TopCategories i {
      font-size: 25px !important; } }
  .HomeWidget .TopCategory {
    align-items: center;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-bottom: 16px;
    height: 130px;
    text-align: center;
    background: #ffffff;
    border-top: solid 6px; }
    .irc .HomeWidget .TopCategory {
      border-color: #FCD735; }
    .nrc .HomeWidget .TopCategory {
      border-color: #000000; }
    .generic .HomeWidget .TopCategory {
      border-color: #339933; }
    .mc .HomeWidget .TopCategory {
      border-color: #000000; }
    .pg .HomeWidget .TopCategory {
      border-color: #71D1A7; }
    .cuenta .HomeWidget .TopCategory {
      border-color: #339933; }
    .ri .HomeWidget .TopCategory {
      border-color: #005377; }
    .HomeWidget .TopCategory:hover .icon {
      color: black; }
    @media (max-width: 1000px) {
      .HomeWidget .TopCategory {
        display: flex;
        height: auto;
        text-align: left;
        width: 100%;
        margin: 3% 0; } }
    @media (min-width: 1000px) {
      .HomeWidget .TopCategory {
        margin-bottom: 5%; } }
    .HomeWidget .TopCategory .icon {
      color: #555;
      display: flex;
      flex-direction: row;
      align-items: center;
      display: inline-block;
      justify-content: center;
      font-size: 120%;
      justify-self: center; }
      @media (min-width: 1000px) {
        .HomeWidget .TopCategory .icon {
          width: 100%;
          text-align: center;
          font-size: 50px; } }
      .rtl .HomeWidget .TopCategory .icon {
        padding-left: 10px; }
      .HomeWidget .TopCategory .icon .material-icons {
        font-size: 50px; }

.CT-widget {
  flex-basis: calc(100% - 40px); }
  @media (min-width: 1000px) {
    .CT-widget {
      max-width: 1024px; } }

.top-container {
  display: flex;
  align-items: center;
  margin-bottom: 3%; }
  .top-container .title-line {
    border-left: 8px solid;
    height: 50px;
    margin-right: 20px; }
    .irc .top-container .title-line {
      border-color: #FCD735; }
    .nrc .top-container .title-line {
      border-color: #000000; }
    .generic .top-container .title-line {
      border-color: #339933; }
    .mc .top-container .title-line {
      border-color: #000000; }
    .pg .top-container .title-line {
      border-color: #71D1A7; }
    .cuenta .top-container .title-line {
      border-color: #339933; }
    .ri .top-container .title-line {
      border-color: #005377; }
