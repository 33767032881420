a {
  text-decoration: none; }

.ServiceDetail a {
  text-decoration: underline; }

.ServiceDetail a:hover {
  text-decoration: none;
  border-bottom: 2px black solid; }
  .irc .ServiceDetail a:hover {
    border-bottom-color: #ffda1a; }
  .nrc .ServiceDetail a:hover {
    border-bottom-color: #ff7602; }
  .generic .ServiceDetail a:hover {
    border-bottom-color: #29991f; }
  .mc .ServiceDetail a:hover {
    border-bottom-color: #e60000; }
  .pg .ServiceDetail a:hover {
    border-bottom-color: #59c2ed; }
  .cuenta .ServiceDetail a:hover {
    border-bottom-color: #29991f; }
  .ri .ServiceDetail a:hover {
    border-bottom-color: #cf5c36; }

.ServiceDetail .openingTable .not-visible {
  display: none; }

.ServiceDetail .service-invalid {
  text-align: center;
  font-size: 20px;
  font-weight: normal;
  font-style: italic;
  margin-top: 0px; }
