a {
  text-decoration: none; }

.LocalGuide .top-container .title-line.rtl {
  margin-right: 0;
  margin-left: 20px; }

.LocalGuide .top-container .title-line.ltr {
  margin-left: 0;
  margin-right: 20px; }

.LocalGuide .top-container h3 {
  font-size: 25px;
  text-transform: none; }

.ltr .LocalGuide s {
  float: right; }

.rtl .LocalGuide s {
  float: left; }

.LocalGuide .Container {
  display: grid;
  width: 100%;
  grid-template-columns: 100%; }
  @media (min-width: 1000px) {
    .LocalGuide .Container {
      grid-template-columns: 48% 48%;
      grid-column-gap: 4%; } }
  .LocalGuide .Container .LocalGuideItem {
    display: block;
    flex-basis: calc(50% - 5px);
    margin-bottom: -1rem;
    text-align: center;
    justify-content: center; }
    .rtl .LocalGuide .Container .LocalGuideItem {
      margin-left: 10px;
      margin-right: 0; }
    .LocalGuide .Container .LocalGuideItem img {
      height: 14rem;
      width: 100%;
      object-fit: cover; }
    .LocalGuide .Container .LocalGuideItem .text-container {
      position: relative;
      background: white;
      padding: 10px 25px 30px 25px;
      top: -4rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      max-width: 400px; }
      .LocalGuide .Container .LocalGuideItem .text-container .title {
        font-size: 26px;
        text-transform: none; }
      .LocalGuide .Container .LocalGuideItem .text-container .description {
        font-weight: 300;
        text-align: left;
        margin-bottom: 1rem; }
      .LocalGuide .Container .LocalGuideItem .text-container .see-more-article {
        font-weight: 600;
        text-decoration: underline; }
      .LocalGuide .Container .LocalGuideItem .text-container.rtl .description {
        text-align: right; }
    .LocalGuide .Container .LocalGuideItem.first-item .text-container {
      background: #339933; }
    @media (min-width: 1000px) {
      .LocalGuide .Container .LocalGuideItem {
        margin-bottom: -6%; }
        .LocalGuide .Container .LocalGuideItem .text-container {
          top: -4rem;
          max-width: 326px; }
        .LocalGuide .Container .LocalGuideItem.first-item {
          grid-column-start: 1;
          grid-column-end: 3;
          margin-bottom: 35px; }
          .LocalGuide .Container .LocalGuideItem.first-item .Overlay img {
            margin-right: auto;
            height: 25rem;
            width: 70%; }
          .LocalGuide .Container .LocalGuideItem.first-item .Overlay .text-container {
            position: absolute;
            top: 37%;
            left: 64%;
            padding: 20px 30px 30px 30px; }
            .irc .LocalGuide .Container .LocalGuideItem.first-item .Overlay .text-container {
              background-color: #7FB685; }
            .nrc .LocalGuide .Container .LocalGuideItem.first-item .Overlay .text-container {
              background-color: #000000; }
            .generic .LocalGuide .Container .LocalGuideItem.first-item .Overlay .text-container {
              background-color: #339933; }
            .mc .LocalGuide .Container .LocalGuideItem.first-item .Overlay .text-container {
              background-color: #000000; }
            .pg .LocalGuide .Container .LocalGuideItem.first-item .Overlay .text-container {
              background-color: #000000; }
            .cuenta .LocalGuide .Container .LocalGuideItem.first-item .Overlay .text-container {
              background-color: #339933; }
            .ri .LocalGuide .Container .LocalGuideItem.first-item .Overlay .text-container {
              background-color: #cf5c36; }
            .LocalGuide .Container .LocalGuideItem.first-item .Overlay .text-container .see-more-article {
              color: black; }
        .LocalGuide .Container .LocalGuideItem.last-item {
          grid-column-start: 1;
          grid-column-end: 3;
          margin-bottom: 35px; }
          .LocalGuide .Container .LocalGuideItem.last-item .Overlay img {
            margin-right: auto;
            height: 25rem;
            width: 70%; }
          .LocalGuide .Container .LocalGuideItem.last-item .Overlay .text-container {
            position: absolute;
            top: 37%;
            left: 64%;
            padding: 20px 30px 30px 30px; }
            .irc .LocalGuide .Container .LocalGuideItem.last-item .Overlay .text-container {
              background-color: #7FB685; }
            .nrc .LocalGuide .Container .LocalGuideItem.last-item .Overlay .text-container {
              background-color: #000000; }
            .generic .LocalGuide .Container .LocalGuideItem.last-item .Overlay .text-container {
              background-color: #339933; }
            .mc .LocalGuide .Container .LocalGuideItem.last-item .Overlay .text-container {
              background-color: #000000; }
            .pg .LocalGuide .Container .LocalGuideItem.last-item .Overlay .text-container {
              background-color: #000000; }
            .cuenta .LocalGuide .Container .LocalGuideItem.last-item .Overlay .text-container {
              background-color: #339933; }
            .ri .LocalGuide .Container .LocalGuideItem.last-item .Overlay .text-container {
              background-color: #cf5c36; }
            .LocalGuide .Container .LocalGuideItem.last-item .Overlay .text-container .see-more-article {
              color: black; } }
  .LocalGuide .Container .Overlay {
    position: relative;
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: center;
    flex-direction: column; }
    .LocalGuide .Container .Overlay .fa {
      font-size: 20px;
      padding: 10px; }
    @media (min-width: 1000px) {
      .LocalGuide .Container .Overlay {
        font-size: 16px; } }

.LocalGuide .read-more-container {
  display: flex;
  justify-content: center;
  align-items: center; }
  .LocalGuide .read-more-container .Read-More {
    text-decoration: underline; }
    .LocalGuide .read-more-container .Read-More a {
      cursor: pointer;
      font-size: 18px;
      font-weight: 900; }
