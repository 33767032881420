a {
  text-decoration: none; }

.Article {
  position: relative; }
  @media (min-width: 1000px) {
    .Article {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      .Article .Read-More-lead {
        display: flex; }
      .Article .Read-More-secondary {
        display: none; } }
  .Article .Facebook {
    width: 100%; }
  .Article .YouTube {
    width: 100%; }
  .Article h3 {
    text-transform: uppercase; }
  .Article .text-container {
    max-width: 1024px;
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    background: white;
    padding: 1.5% 2%;
    box-sizing: border-box;
    position: relative;
    bottom: 6rem; }
    @media (max-width: 1000px) {
      .Article .text-container {
        display: flex;
        flex-direction: column;
        margin: 0px 20px;
        padding: 20px;
        bottom: 3rem; }
        .Article .text-container .Read-More-lead {
          display: none; }
        .Article .text-container .Read-More-secondary {
          display: flex;
          align-self: center;
          margin-top: 20px; }
          .Article .text-container .Read-More-secondary a {
            font-weight: 600; } }
    .Article .text-container .welcome-container {
      display: flex;
      flex-direction: column;
      margin: 30px 0; }
      .Article .text-container .welcome-container.only {
        margin: unset; }
      @media (max-width: 1000px) {
        .Article .text-container .welcome-container {
          margin: 0 0 30px 0; }
          .Article .text-container .welcome-container h1 {
            font-size: 30px !important; }
          .Article .text-container .welcome-container p {
            font-size: 14px; } }
      .Article .text-container .welcome-container h1 {
        font-size: 39px;
        text-transform: none; }
      .Article .text-container .welcome-container p p:nth-child(3) {
        display: none; }
      .Article .text-container .welcome-container p p:nth-child(2) {
        display: none; }
    .Article .text-container .social-media-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .Article .text-container .social-media-container .facebook-container {
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        grid-template-columns: 23% 73%;
        column-gap: 4%;
        background-color: #f3f3f6;
        padding: 5%;
        box-sizing: border-box;
        min-height: 175px; }
        .Article .text-container .social-media-container .facebook-container.rtl {
          grid-template-rows: 19% 49% 28%;
          row-gap: 4%; }
          .Article .text-container .social-media-container .facebook-container.rtl .social-title {
            align-self: flex-start; }
        .Article .text-container .social-media-container .facebook-container.ltr {
          grid-template-rows: 20% 50% 30%; }
          .Article .text-container .social-media-container .facebook-container.ltr .social-title {
            align-self: flex-end; }
        .Article .text-container .social-media-container .facebook-container .icon-container {
          grid-row-start: 1;
          grid-row-end: 4;
          display: flex;
          align-items: center;
          justify-content: center; }
          .Article .text-container .social-media-container .facebook-container .icon-container .icon-background {
            width: 80%;
            height: 80%;
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 8px; }
            .Article .text-container .social-media-container .facebook-container .icon-container .icon-background svg {
              color: #3b5998;
              font-size: 3.5rem; }
        .Article .text-container .social-media-container .facebook-container h1 {
          margin: 0;
          text-transform: none; }
        .Article .text-container .social-media-container .facebook-container span {
          align-self: center;
          font-size: 14px; }
        .Article .text-container .social-media-container .facebook-container a {
          width: 100%;
          color: #fff;
          border: 1px solid transparent;
          font-size: 1rem;
          line-height: 1.5;
          box-sizing: border-box;
          justify-content: center;
          display: flex;
          height: 100%;
          align-items: center;
          border-radius: 5px; }
          .irc .Article .text-container .social-media-container .facebook-container a {
            background-color: #7FB685; }
          .nrc .Article .text-container .social-media-container .facebook-container a {
            background-color: #000000; }
          .generic .Article .text-container .social-media-container .facebook-container a {
            background-color: #3D3FDA; }
          .mc .Article .text-container .social-media-container .facebook-container a {
            background-color: #000000; }
          .pg .Article .text-container .social-media-container .facebook-container a {
            background-color: #000000; }
          .cuenta .Article .text-container .social-media-container .facebook-container a {
            background-color: #3D3FDA; }
          .ri .Article .text-container .social-media-container .facebook-container a {
            background-color: #cf5c36; }
          .irc .Article .text-container .social-media-container .facebook-container a {
            border-color: #7FB685; }
          .nrc .Article .text-container .social-media-container .facebook-container a {
            border-color: #000000; }
          .generic .Article .text-container .social-media-container .facebook-container a {
            border-color: #3D3FDA; }
          .mc .Article .text-container .social-media-container .facebook-container a {
            border-color: #000000; }
          .pg .Article .text-container .social-media-container .facebook-container a {
            border-color: #000000; }
          .cuenta .Article .text-container .social-media-container .facebook-container a {
            border-color: #3D3FDA; }
          .ri .Article .text-container .social-media-container .facebook-container a {
            border-color: #cf5c36; }
        @media (max-width: 1000px) {
          .Article .text-container .social-media-container .facebook-container .icon-container {
            grid-row-end: 3;
            justify-content: center;
            margin-bottom: 16px; }
            .Article .text-container .social-media-container .facebook-container .icon-container .icon-background {
              width: 50%; }
              .Article .text-container .social-media-container .facebook-container .icon-container .icon-background svg {
                font-size: 2rem; }
          .Article .text-container .social-media-container .facebook-container .social-title {
            font-size: 1em; }
          .Article .text-container .social-media-container .facebook-container .social-text {
            font-size: 13px; }
          .Article .text-container .social-media-container .facebook-container a {
            grid-column-start: 1;
            grid-column-end: 3; } }
        .Article .text-container .social-media-container .facebook-container.only {
          height: unset; }
      .Article .text-container .social-media-container .whatsapp-container {
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        grid-template-columns: 23% 73%;
        column-gap: 4%;
        background-color: #f3f3f6;
        padding: 5%;
        box-sizing: border-box;
        min-height: 175px;
        margin-bottom: 1rem; }
        .Article .text-container .social-media-container .whatsapp-container.rtl {
          grid-template-rows: 19% 49% 28%;
          row-gap: 4%; }
          .Article .text-container .social-media-container .whatsapp-container.rtl .social-title {
            align-self: flex-start; }
        .Article .text-container .social-media-container .whatsapp-container.ltr {
          grid-template-rows: 20% 50% 30%; }
          .Article .text-container .social-media-container .whatsapp-container.ltr .social-title {
            align-self: flex-end; }
        .Article .text-container .social-media-container .whatsapp-container .icon-container {
          grid-row-start: 1;
          grid-row-end: 4;
          display: flex;
          align-items: center;
          justify-content: center; }
          .Article .text-container .social-media-container .whatsapp-container .icon-container .icon-background {
            width: 80%;
            height: 80%;
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 8px; }
            .Article .text-container .social-media-container .whatsapp-container .icon-container .icon-background svg {
              color: #3b5998;
              font-size: 3.5rem; }
        .Article .text-container .social-media-container .whatsapp-container h1 {
          margin: 0;
          text-transform: none; }
        .Article .text-container .social-media-container .whatsapp-container span {
          align-self: center;
          font-size: 14px; }
        .Article .text-container .social-media-container .whatsapp-container a {
          width: 100%;
          color: #fff;
          border: 1px solid transparent;
          font-size: 1rem;
          line-height: 1.5;
          box-sizing: border-box;
          justify-content: center;
          display: flex;
          height: 100%;
          align-items: center;
          border-radius: 5px; }
          .irc .Article .text-container .social-media-container .whatsapp-container a {
            background-color: #7FB685; }
          .nrc .Article .text-container .social-media-container .whatsapp-container a {
            background-color: #000000; }
          .generic .Article .text-container .social-media-container .whatsapp-container a {
            background-color: #3D3FDA; }
          .mc .Article .text-container .social-media-container .whatsapp-container a {
            background-color: #000000; }
          .pg .Article .text-container .social-media-container .whatsapp-container a {
            background-color: #000000; }
          .cuenta .Article .text-container .social-media-container .whatsapp-container a {
            background-color: #3D3FDA; }
          .ri .Article .text-container .social-media-container .whatsapp-container a {
            background-color: #cf5c36; }
          .irc .Article .text-container .social-media-container .whatsapp-container a {
            border-color: #7FB685; }
          .nrc .Article .text-container .social-media-container .whatsapp-container a {
            border-color: #000000; }
          .generic .Article .text-container .social-media-container .whatsapp-container a {
            border-color: #3D3FDA; }
          .mc .Article .text-container .social-media-container .whatsapp-container a {
            border-color: #000000; }
          .pg .Article .text-container .social-media-container .whatsapp-container a {
            border-color: #000000; }
          .cuenta .Article .text-container .social-media-container .whatsapp-container a {
            border-color: #3D3FDA; }
          .ri .Article .text-container .social-media-container .whatsapp-container a {
            border-color: #cf5c36; }
        @media (max-width: 1000px) {
          .Article .text-container .social-media-container .whatsapp-container .icon-container {
            grid-row-end: 3;
            justify-content: center;
            margin-bottom: 16px; }
            .Article .text-container .social-media-container .whatsapp-container .icon-container .icon-background {
              width: 50%; }
              .Article .text-container .social-media-container .whatsapp-container .icon-container .icon-background svg {
                font-size: 2rem; }
          .Article .text-container .social-media-container .whatsapp-container .social-title {
            font-size: 1em; }
          .Article .text-container .social-media-container .whatsapp-container .social-text {
            font-size: 13px; }
          .Article .text-container .social-media-container .whatsapp-container a {
            grid-column-start: 1;
            grid-column-end: 3; } }
        .Article .text-container .social-media-container .whatsapp-container.only {
          height: unset; }
        .Article .text-container .social-media-container .whatsapp-container .fa-whatsapp {
          color: #25d366 !important; }
  .Article .faq-container {
    width: 100%; }
  .Article p {
    margin-top: 0;
    font-weight: 300;
    line-height: 1.6;
    opacity: 1; }
    .Article p u {
      text-decoration: none; }
    .Article p p:hover {
      opacity: 0.85; }
    .Article p a {
      color: black !important; }
  .Article .hero {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
    .Article .hero .heroImage {
      object-fit: cover;
      align-self: baseline; }
  .Article .hero > img {
    width: 100%; }
  @media (min-width: 1000px) {
    .Article .hero .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0.5;
      background-color: #000000; } }
  .Article .pos,
  .Article .neg {
    display: none;
    visibility: hidden;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0; }
  .Article .pos + ul,
  .Article .neg + ul {
    padding: 0; }
    .Article .pos + ul li,
    .Article .neg + ul li {
      list-style: none; }
      .Article .pos + ul li::before,
      .Article .neg + ul li::before {
        font-family: "Font Awesome 5 Pro"; }
  .Article .pos + ul li::before {
    content: "\f00c ";
    color: green;
    font-weight: 900; }
  .Article .neg + ul li::before {
    content: "\f00d ";
    color: red; }
  .Article .Read-More-lead {
    text-decoration: underline;
    margin: 26px 0; }
  .Article .Read-More-secondary {
    text-decoration: underline; }

.faq p, .faq a {
  text-decoration: underline;
  display: block;
  margin-top: 0;
  font-weight: 300;
  line-height: 1.6;
  opacity: 1;
  margin-bottom: 1em; }
