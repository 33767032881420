a {
  text-decoration: none; }

.ServiceCategoryList {
  padding: 0 !important; }

.ServiceCategoryList,
.ServiceListContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 5px; }
  .ServiceCategoryList hr,
  .ServiceListContainer hr {
    padding: 0;
    margin: auto;
    border: 0;
    border-top-width: 1px;
    border-top-color: #ccc;
    border-style: solid;
    border-radius: 1px;
    height: 0;
    opacity: 0.6; }
  .ServiceCategoryList .MenuIcon,
  .ServiceListContainer .MenuIcon {
    font-size: 20px; }
  .ServiceCategoryList .Spacer,
  .ServiceListContainer .Spacer {
    height: 30vh;
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center; }
  .ServiceCategoryList .footer,
  .ServiceListContainer .footer {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    overflow: hidden; }
    @media (min-width: 1000px) {
      .ServiceCategoryList .footer,
      .ServiceListContainer .footer {
        border-radius: 5px; } }
    .ServiceCategoryList .footer .Selector,
    .ServiceListContainer .footer .Selector {
      border-bottom: 1px solid #ddd; }
      .ServiceCategoryList .footer .Selector:last-child,
      .ServiceListContainer .footer .Selector:last-child {
        border-bottom: none; }
      .ServiceCategoryList .footer .Selector.related h1,
      .ServiceListContainer .footer .Selector.related h1 {
        font-weight: normal; }
      .ServiceCategoryList .footer .Selector.back,
      .ServiceListContainer .footer .Selector.back {
        border-top: 1px solid #ddd; }
      .ServiceCategoryList .footer .Selector .icon-placeholder,
      .ServiceListContainer .footer .Selector .icon-placeholder {
        align-items: center;
        background-color: #ccc;
        display: flex;
        justify-content: center;
        min-width: 50px; }
  .ServiceCategoryList .Selector,
  .ServiceListContainer .Selector {
    width: 100%;
    display: flex;
    margin: 0;
    background-color: #f0f0f0;
    min-height: 60px;
    flex-direction: row;
    align-items: stretch;
    cursor: pointer; }
    .ServiceCategoryList .Selector:active,
    .ServiceListContainer .Selector:active {
      background-color: #e3e3e3; }
  .ServiceCategoryList .Selector > h1,
  .ServiceListContainer .Selector > h1 {
    align-items: center;
    display: flex;
    font-size: 15px;
    margin: 0;
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .ServiceCategoryList .Selector > h1 a,
    .ServiceListContainer .Selector > h1 a {
      font-weight: normal;
      line-height: 20px;
      margin-left: 5px;
      margin-right: 5px; }
    .ServiceCategoryList .Selector > h1 .field,
    .ServiceListContainer .Selector > h1 .field {
      font-weight: normal; }
    .ServiceCategoryList .Selector > h1 .phoneFormat,
    .ServiceListContainer .Selector > h1 .phoneFormat {
      display: inline-block;
      direction: ltr; }
    .ServiceCategoryList .Selector > h1 .noPhoneFormat,
    .ServiceListContainer .Selector > h1 .noPhoneFormat {
      display: inline-block; }
  .ServiceCategoryList .Selector > h1 > small,
  .ServiceListContainer .Selector > h1 > small {
    font-size: 12px;
    display: block;
    width: 100%;
    color: gray; }
  .ServiceCategoryList ul,
  .ServiceListContainer ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%; }
  .ServiceCategoryList ul li .container,
  .ServiceListContainer ul li .container {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer; }
    .ServiceCategoryList ul li .container:active,
    .ServiceListContainer ul li .container:active {
      background-color: #f6f6f6; }
    .ServiceCategoryList ul li .container .material-icons,
    .ServiceListContainer ul li .container .material-icons {
      font-size: 24px;
      margin-left: auto;
      padding-right: 0; }
  .ServiceCategoryList ul li .disabled,
  .ServiceListContainer ul li .disabled {
    color: grey;
    cursor: default; }
  .ServiceCategoryList > ul li .container > i,
  .ServiceListContainer > ul li .container > i {
    padding-right: 10px;
    width: 25px;
    font-size: 16px;
    line-height: 24px; }
  .ServiceCategoryList > ul li .container > strong,
  .ServiceListContainer > ul li .container > strong {
    font-size: 16px;
    min-height: 24px;
    line-height: 24px; }
  .ServiceCategoryList hr,
  .ServiceListContainer hr {
    padding: 0;
    margin: auto;
    border: 0;
    border-top-width: 1px;
    border-top-color: #ddd;
    border-style: solid;
    border-radius: 1px;
    height: 0;
    width: 95%; }
  .ServiceCategoryList .right,
  .ServiceListContainer .right {
    display: flex;
    flex-grow: 1;
    align-self: flex-end;
    align-items: flex-end;
    flex-direction: column; }

.desktop {
  max-width: 1024px;
  margin: 0 auto; }

.ServiceCategoryList .CategoryContainer {
  flex: 1;
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  height: 100px; }
  @media (min-width: 1000px) {
    .ServiceCategoryList .CategoryContainer {
      flex-basis: 25%; } }
  .ServiceCategoryList .CategoryContainer i {
    font-size: 35px; }

.ServiceCategoryList .Category {
  display: flex;
  flex-direction: column;
  margin: 2px 2px;
  border: 1px black solid;
  border-radius: 5px;
  flex: 1;
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  text-align: center; }
  .irc .ServiceCategoryList .Category {
    background-color: #ffda1a; }
  .nrc .ServiceCategoryList .Category {
    background-color: #ff7602; }
  .generic .ServiceCategoryList .Category {
    background-color: #29991f; }
  .mc .ServiceCategoryList .Category {
    background-color: #e60000; }
  .pg .ServiceCategoryList .Category {
    background-color: #59c2ed; }
  .cuenta .ServiceCategoryList .Category {
    background-color: #29991f; }
  .ri .ServiceCategoryList .Category {
    background-color: #cf5c36; }
  .irc .ServiceCategoryList .Category {
    color: #000000; }
  .nrc .ServiceCategoryList .Category {
    color: #ffffff; }
  .generic .ServiceCategoryList .Category {
    color: #ffffff; }
  .mc .ServiceCategoryList .Category {
    color: #ffffff; }
  .pg .ServiceCategoryList .Category {
    color: #ffffff; }
  .cuenta .ServiceCategoryList .Category {
    color: #ffffff; }
  .ri .ServiceCategoryList .Category {
    color: #ffffff; }
  .ServiceCategoryList .Category span {
    line-height: 20px;
    font-size: 16px; }
  .irc .ServiceCategoryList .Category:active {
    background-color: #806b00; }
  .nrc .ServiceCategoryList .Category:active {
    background-color: #683000; }
  .generic .ServiceCategoryList .Category:active {
    background-color: #071a05; }
  .mc .ServiceCategoryList .Category:active {
    background-color: #4d0000; }
  .pg .ServiceCategoryList .Category:active {
    background-color: #11749c; }
  .cuenta .ServiceCategoryList .Category:active {
    background-color: #071a05; }
  .ri .ServiceCategoryList .Category:active {
    background-color: #572515; }

.ServiceCategoryList > .Title {
  width: 100%;
  display: flex;
  margin: 0;
  background-color: #f0f0f0;
  min-height: 60px;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  text-align: center; }

.ServiceCategoryList .Title > h1 {
  font-size: 18px;
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px;
  flex: 1; }

.ServiceCategoryList .Title > h1 > small {
  font-size: 12px;
  display: block;
  width: 100%;
  color: gray; }

.ServiceCategoryList .NoServices > h2 {
  padding: 20px;
  font-style: italic; }

.ServiceMapContainer {
  z-index: 0; }
  .ServiceMapContainer .gm-style-iw {
    left: 20px;
    padding: 0 !important; }
    .ServiceMapContainer .gm-style-iw button.gm-ui-hover-effect {
      right: 0 !important;
      top: 0 !important; }
  .ServiceMapContainer .gm-style .gm-style-iw-d {
    overflow: auto !important; }
    .ServiceMapContainer .gm-style .gm-style-iw-d .arrow {
      padding-left: 10px;
      justify-self: center;
      align-self: center;
      font-size: 24px;
      width: 24px; }
  .ServiceMapContainer .gm-style .gm-style-iw-t::after {
    left: 20px;
    top: -1px; }

.ServiceList,
.ServiceMap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; }
  .ServiceList #MapCanvas, .ServiceList #MapCanvas2,
  .ServiceMap #MapCanvas,
  .ServiceMap #MapCanvas2 {
    height: 400px;
    z-index: 0; }
    @media (min-width: 800px) {
      .ServiceList #MapCanvas, .ServiceList #MapCanvas2,
      .ServiceMap #MapCanvas,
      .ServiceMap #MapCanvas2 {
        height: 600px; } }
  .ServiceList hr,
  .ServiceMap hr {
    padding: 0;
    margin: auto;
    border: 0;
    border-top-width: 1px;
    border-top-color: #ccc;
    border-style: solid;
    border-radius: 1px;
    height: 0;
    opacity: 0.6; }
  .ServiceList .MenuIcon,
  .ServiceMap .MenuIcon {
    font-size: 20px; }
  .ServiceList .Spacer,
  .ServiceMap .Spacer {
    height: 30vh;
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center; }
  .ServiceList .footer,
  .ServiceMap .footer {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    overflow: hidden; }
    @media (min-width: 1000px) {
      .ServiceList .footer,
      .ServiceMap .footer {
        border-radius: 5px; } }
    .ServiceList .footer .Selector,
    .ServiceMap .footer .Selector {
      border-bottom: 1px solid #ddd; }
      .ServiceList .footer .Selector:last-child,
      .ServiceMap .footer .Selector:last-child {
        border-bottom: none; }
      .ServiceList .footer .Selector.related h1,
      .ServiceMap .footer .Selector.related h1 {
        font-weight: normal; }
      .ServiceList .footer .Selector.back,
      .ServiceMap .footer .Selector.back {
        border-top: 1px solid #ddd; }
      .ServiceList .footer .Selector .icon-placeholder,
      .ServiceMap .footer .Selector .icon-placeholder {
        align-items: center;
        background-color: #ccc;
        display: flex;
        justify-content: center;
        min-width: 50px; }
  .ServiceList .Selector,
  .ServiceMap .Selector {
    width: 100%;
    display: flex;
    margin: 0;
    background-color: #f0f0f0;
    min-height: 60px;
    flex-direction: row;
    align-items: stretch;
    cursor: pointer; }
    .ServiceList .Selector:active,
    .ServiceMap .Selector:active {
      background-color: #e3e3e3; }
  .ServiceList .Selector > h1,
  .ServiceMap .Selector > h1 {
    align-items: center;
    display: flex;
    font-size: 15px;
    margin: 0;
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .ServiceList .Selector > h1 a,
    .ServiceMap .Selector > h1 a {
      font-weight: normal;
      line-height: 20px;
      margin-left: 5px;
      margin-right: 5px; }
    .ServiceList .Selector > h1 .field,
    .ServiceMap .Selector > h1 .field {
      font-weight: normal; }
    .ServiceList .Selector > h1 .phoneFormat,
    .ServiceMap .Selector > h1 .phoneFormat {
      display: inline-block;
      direction: ltr; }
    .ServiceList .Selector > h1 .noPhoneFormat,
    .ServiceMap .Selector > h1 .noPhoneFormat {
      display: inline-block; }
  .ServiceList .Selector > h1 > small,
  .ServiceMap .Selector > h1 > small {
    font-size: 12px;
    display: block;
    width: 100%;
    color: gray; }
  .ServiceList .Title,
  .ServiceMap .Title {
    width: 100%;
    display: flex;
    margin: 0;
    background-color: #f0f0f0;
    min-height: 60px;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase; }
  .ServiceList .Title > h1,
  .ServiceMap .Title > h1 {
    font-size: 18px;
    display: block;
    width: 100%;
    margin: 0;
    padding: 10px;
    flex: 1; }
  .ServiceList .Title > h1 > small,
  .ServiceMap .Title > h1 > small {
    font-size: 12px;
    display: block;
    width: 100%;
    color: gray; }
  .ServiceList .Items,
  .ServiceMap .Items {
    flex: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    margin: 0; }
  .ServiceList .Item,
  .ServiceMap .Item {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-basis: 100%;
    min-height: 42px;
    margin: 2px 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .ServiceList .Item.service-map,
    .ServiceMap .Item.service-map {
      background-color: transparent;
      border: none; }
    @media (min-width: 1000px) {
      .ServiceList .Item,
      .ServiceMap .Item {
        flex-basis: calc(50% - 10px);
        margin: 5px; } }
    .ServiceList .Item i.material-icons,
    .ServiceMap .Item i.material-icons {
      justify-self: center;
      align-self: center; }
      .rtl .ServiceList .Item i.material-icons:after, .rtl
      .ServiceMap .Item i.material-icons:after {
        content: "keyboard_arrow_left"; }
      .ltr .ServiceList .Item i.material-icons:after, .ltr
      .ServiceMap .Item i.material-icons:after {
        content: "keyboard_arrow_right"; }
    .ServiceList .Item:active,
    .ServiceMap .Item:active {
      background-color: #f6f6f6; }
    .ServiceList .Item:hover,
    .ServiceMap .Item:hover {
      border: 1px solid #ccc; }
      .ServiceList .Item:hover h1,
      .ServiceMap .Item:hover h1 {
        text-decoration: underline; }
    .ServiceList .Item > .Icon,
    .ServiceMap .Item > .Icon {
      padding-top: 10px;
      text-align: center;
      width: 56px; }
  .ServiceList .Icons,
  .ServiceMap .Icons {
    display: flex;
    margin: 10px 0 0;
    flex-wrap: wrap; }
    .ServiceList .Icons .Icon,
    .ServiceMap .Icons .Icon {
      padding-right: 15px; }
  .ServiceList .Info,
  .ServiceMap .Info {
    display: flex;
    flex: 1;
    flex-direction: column; }
    .ServiceList .Info h1,
    .ServiceMap .Info h1 {
      font-size: 100%;
      font-weight: bold; }
    .ServiceList .Info h2,
    .ServiceMap .Info h2 {
      margin: 0;
      padding: 0;
      font-size: 95%;
      font-weight: normal; }
      .ServiceList .Info h2 span,
      .ServiceMap .Info h2 span {
        color: gray;
        display: block;
        font-size: 90%;
        padding-top: 12px; }
  .ServiceList .Unavailable li > h1,
  .ServiceMap .Unavailable li > h1 {
    font-size: 95%;
    margin-top: 30px;
    text-align: center; }
  .ServiceList .Unavailable .Item,
  .ServiceMap .Unavailable .Item {
    opacity: 0.38;
    pointer-events: none; }
    .ServiceList .Unavailable .Item .Icon i::before,
    .ServiceMap .Unavailable .Item .Icon i::before {
      color: black; }
  .ServiceList .Error,
  .ServiceMap .Error {
    padding: 16px 16px; }

.ServiceList .Icons {
  margin-bottom: 10px; }

@media (min-width: 1000px) {
  .ServiceList .Item {
    max-width: calc(50% - 10px); } }

.ServiceMap {
  border: 1px solid #ddd; }
  .ServiceMap .leaflet-popup-tip-container {
    display: block;
    margin-top: -6px; }
    .ServiceMap .leaflet-popup-tip-container .leaflet-popup-tip {
      box-shadow: none; }
  .ServiceMap .leaflet-control-attribution a {
    font-size: 1em; }
  .ServiceMap .leaflet-popup {
    width: 300px;
    left: -150px; }
  .ServiceMap .leaflet-popup-content-wrapper {
    background-color: transparent;
    box-shadow: none; }
  .ServiceMap .leaflet-popup-content .Item {
    border-bottom: 0;
    cursor: pointer;
    display: block !important;
    font-size: 14px; }
    .ServiceMap .leaflet-popup-content .Item .Item-content {
      padding: 0 10px; }
    .ServiceMap .leaflet-popup-content .Item .Icons {
      display: flex;
      width: 95%;
      flex-direction: row;
      margin-top: 15px; }
      .ServiceMap .leaflet-popup-content .Item .Icons .Icon {
        display: flex;
        min-width: 20px;
        padding: 0 10px 15px 5px; }
        .ServiceMap .leaflet-popup-content .Item .Icons .Icon i {
          width: 100%;
          height: 100%;
          font-size: 18px;
          text-align: center;
          align-items: center;
          display: flex;
          justify-content: center; }
    .ServiceMap .leaflet-popup-content .Item .Info .title {
      background-color: #f5f5f5;
      border-bottom: 1px solid #eee;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      display: flex;
      justify-content: space-between; }
    .ServiceMap .leaflet-popup-content .Item .Info h1 {
      font-size: 120%;
      margin: 0;
      padding: 10px 0; }
      .ServiceMap .leaflet-popup-content .Item .Info h1:hover {
        text-decoration: underline; }
    .ServiceMap .leaflet-popup-content .Item .Info h2 {
      font-size: 110%;
      margin: 10px 0 15px; }
    .ServiceMap .leaflet-popup-content .Item .Info address {
      color: gray;
      font-size: 95%; }
    .ServiceMap .leaflet-popup-content .Item .Info .fullAddress {
      font-style: normal; }
    .ServiceMap .leaflet-popup-content .Item .Info .regionTitle {
      font-style: normal;
      margin-top: 5px; }
  .ServiceMap .leaflet-div-icon {
    background: white;
    border: none;
    border-radius: 40px;
    box-shadow: 0 0 0 5px rgba(0, 40, 80, 0.15);
    height: 40px !important;
    width: 40px !important; }
    .ServiceMap .leaflet-div-icon .Icon {
      font-size: 22px;
      text-align: center; }
      .ServiceMap .leaflet-div-icon .Icon i {
        position: relative;
        top: 5px; }
  .ServiceMap .marker-cluster {
    border-radius: 50px !important;
    height: 50px !important;
    margin-left: -25px !important;
    margin-top: -25px !important;
    width: 50px !important; }
  .ServiceMap .marker-cluster div {
    border-radius: 50px !important;
    font-size: 14px;
    height: 38px;
    line-height: 38px;
    margin-left: 6px;
    margin-top: 6px;
    text-shadow: 0 0 1px white, 0 0 1px white, 0 0 1px white;
    width: 38px; }
  .ServiceMap .marker-cluster-small, .ServiceMap .marker-cluster-small div {
    background-color: rgba(250, 120, 150, 0.6); }

.ServiceDetail {
  flex-wrap: wrap;
  max-width: 1024px;
  margin: 0 auto; }
  .ServiceDetail hr {
    padding: 0;
    margin: auto;
    border: 0;
    border-top-width: 1px;
    border-top-color: #ccc;
    border-style: solid;
    border-radius: 1px;
    height: 0;
    opacity: 0.6; }
  .ServiceDetail .MenuIcon {
    font-size: 20px; }
  .ServiceDetail .Spacer {
    height: 30vh;
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center; }
  .ServiceDetail .footer {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    overflow: hidden; }
    @media (min-width: 1000px) {
      .ServiceDetail .footer {
        border-radius: 5px; } }
    .ServiceDetail .footer .Selector {
      border-bottom: 1px solid #ddd; }
      .ServiceDetail .footer .Selector:last-child {
        border-bottom: none; }
      .ServiceDetail .footer .Selector.related h1 {
        font-weight: normal; }
      .ServiceDetail .footer .Selector.back {
        border-top: 1px solid #ddd; }
      .ServiceDetail .footer .Selector .icon-placeholder {
        align-items: center;
        background-color: #ccc;
        display: flex;
        justify-content: center;
        min-width: 50px; }
  .ServiceDetail .Selector {
    width: 100%;
    display: flex;
    margin: 0;
    background-color: #f0f0f0;
    min-height: 60px;
    flex-direction: row;
    align-items: stretch;
    cursor: pointer; }
    .ServiceDetail .Selector:active {
      background-color: #e3e3e3; }
  .ServiceDetail .Selector > h1 {
    align-items: center;
    display: flex;
    font-size: 15px;
    margin: 0;
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .ServiceDetail .Selector > h1 a {
      font-weight: normal;
      line-height: 20px;
      margin-left: 5px;
      margin-right: 5px; }
    .ServiceDetail .Selector > h1 .field {
      font-weight: normal; }
    .ServiceDetail .Selector > h1 .phoneFormat {
      display: inline-block;
      direction: ltr; }
    .ServiceDetail .Selector > h1 .noPhoneFormat {
      display: inline-block; }
  .ServiceDetail .Selector > h1 > small {
    font-size: 12px;
    display: block;
    width: 100%;
    color: gray; }
  .ServiceDetail .openingTable {
    align-items: center;
    display: flex;
    justify-content: center; }
  .ServiceDetail .MapCursor {
    cursor: pointer; }
  .ServiceDetail table {
    background-color: #eee;
    border: 1px solid #ddd;
    border-collapse: collapse;
    margin-bottom: 1em;
    min-width: 100%; }
    @media (min-width: 500px) {
      .ServiceDetail table {
        min-width: 500px; } }
    .ServiceDetail table tr {
      border-bottom: 1px solid #ddd;
      height: 30px; }
      .ServiceDetail table tr:last-child {
        border-bottom: none; }
    .ServiceDetail table td {
      text-align: center; }
      .ServiceDetail table td.week {
        font-weight: bold;
        text-align: start;
        padding-left: 5px;
        padding-right: 5px; }
  .ServiceDetail .RelatedServices {
    background-color: #f0f0f0; }
    .ServiceDetail .RelatedServices .MenuIcon {
      margin: 0px 20px 10px 10px; }
  .ServiceDetail .RelatedServicesTitle {
    margin-left: 15px; }
  .ServiceDetail .HeaderBar h1 {
    text-align: center; }
  @media (min-width: 1000px) {
    .ServiceDetail .HeaderBar h1 {
      padding: 40px 0; } }
  .ServiceDetail .icon {
    margin: 0px 20px 10px 10px; }
  .ServiceDetail .verticalHR {
    border-left: 1px black solid;
    width: 2px;
    display: block;
    height: 40px;
    float: left;
    margin: 10px 10px 0px 15px; }
    .ltr .ServiceDetail .verticalHR {
      margin-right: 20px; }
    .rtl .ServiceDetail .verticalHR {
      margin-left: 20px; }
  .ServiceDetail .selector {
    width: 100%;
    display: flex;
    margin: 0;
    background-color: #f0f0f0;
    min-height: 60px;
    flex-direction: row;
    align-items: center;
    cursor: pointer; }
    .ServiceDetail .selector:active {
      background-color: #e3e3e3; }
    .ServiceDetail .selector.related h1 {
      font-weight: normal; }
  .ServiceDetail .selector > h1 {
    font-size: 14px;
    display: block;
    width: 100%;
    margin: 0;
    padding: 10px 10px 10px 16px;
    flex: 1; }
  .ServiceDetail .sharePage {
    padding: 0px;
    float: left;
    width: 47% !important; }
  .ServiceDetail .sharePage > h1 {
    padding: 0;
    width: auto !important; }
    .ServiceDetail .sharePage > h1:first-child {
      flex: 1; }
      .ltr .ServiceDetail .sharePage > h1:first-child {
        padding-left: 20px; }
      .rtl .ServiceDetail .sharePage > h1:first-child {
        padding-right: 20px; }
  .ServiceDetail .ContactInformation {
    display: 'inline-block';
    overflow: 'hidden';
    white-space: 'nowrap';
    text-overflow: 'ellipsis';
    width: '650px'; }
  .ServiceDetail .sharePage .icon {
    margin: 0px 5px 0px 5px; }
    .ltr .ServiceDetail .sharePage .icon {
      margin-right: 20px; }
    .rtl .ServiceDetail .sharePage .icon {
      margin-left: 20px; }
  .ServiceDetail .Title {
    width: 100%;
    display: flex;
    margin: 0;
    background-color: #f0f0f0;
    min-height: 60px;
    flex-direction: row;
    align-items: center; }
  .ServiceDetail .Title > h1 {
    font-size: 18px;
    display: block;
    width: 100%;
    margin: 0;
    padding: 10px;
    flex: 1; }
  .ServiceDetail .Title > h1 > small {
    font-size: 12px;
    display: block;
    width: 100%;
    color: gray; }
  .ServiceDetail .author {
    color: gray;
    font-size: 12px;
    font-style: italic;
    font-weight: 300;
    float: right; }
  .ServiceDetail .hero {
    background-color: #ffffff;
    border-top: 1px solid #ddd;
    display: flex;
    flex-direction: column; }
    .ServiceDetail .hero a {
      color: white; }
    .ServiceDetail .hero .HeroImageContainer {
      padding: 0;
      margin: auto;
      margin-bottom: -3px; }
  .ServiceDetail .hero .HeroImageContainer > img {
    max-width: 100%; }
  .ServiceDetail article {
    margin-left: 16px;
    margin-right: 16px;
    flex: 1; }
    .ServiceDetail article img {
      max-width: 100%;
      margin: auto;
      display: block; }
    .ServiceDetail article em {
      color: gray;
      font-size: 75%;
      padding-bottom: 15px; }
    .ServiceDetail article h1 {
      font-size: 24px; }
    .ServiceDetail article h2 {
      font-size: 18px; }
      .ServiceDetail article h2.provider {
        font-size: 20px;
        padding: 40px 0 25px; }
        .ServiceDetail article h2.provider .providerName {
          font-weight: normal;
          font-style: italic; }
    .ServiceDetail article h3 {
      font-size: 17px; }
    .ServiceDetail article h3, .ServiceDetail article h4 {
      margin-bottom: 5px;
      margin-top: 15px; }
    .ServiceDetail article p {
      font-size: 16px;
      font-weight: 300;
      line-height: 150%;
      margin-top: 0; }
    .ServiceDetail article img {
      border: 1px solid #ddd; }
    .ServiceDetail article ul li {
      list-style-type: square;
      padding-bottom: 8px; }

#categories {
  left: 183px;
  width: 800px; }

.ServiceMapFilter {
  height: 20px;
  z-index: 400;
  background-color: white;
  position: absolute;
  right: 16px;
  top: 74px;
  width: 200px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  padding: 10px 5px 10px 5px;
  box-shadow: 0px 0px 4px 0px #7f7f7f; }
  .ServiceMapFilter .app-bar-separator {
    float: right;
    margin-right: 5px;
    margin-top: -2px; }
  .ServiceMapFilter .category-name {
    float: right; }
