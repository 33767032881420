a {
  text-decoration: none; }

.Subscribe {
  margin: 0 auto;
  max-width: 1024px; }
  .ltr .Subscribe s {
    float: right; }
  .rtl .Subscribe s {
    float: left; }
  .Subscribe .Subscribe-content {
    border-top: 1px solid #ddd;
    font-weight: 300;
    padding-left: 16px;
    padding-right: 16px; }
    .Subscribe .Subscribe-content .subtitle {
      font-size: 15px; }
    .Subscribe .Subscribe-content .subscribe-form {
      background-color: #f0f0f0;
      border: 1px solid #ddd;
      border-radius: 5px;
      color: #5e5e5e;
      min-height: 214px;
      max-width: 500px;
      margin: 50px auto;
      padding: 10px;
      text-align: center; }
      .Subscribe .Subscribe-content .subscribe-form p {
        font-size: 14px;
        font-style: italic; }
      .Subscribe .Subscribe-content .subscribe-form #phone-wrapper label {
        color: black;
        font-size: 20px; }
      .Subscribe .Subscribe-content .subscribe-form #phone-wrapper #country-code {
        width: 40px; }
      .Subscribe .Subscribe-content .subscribe-form #phone-wrapper #phone-number {
        width: 135px; }
      .Subscribe .Subscribe-content .subscribe-form #phone-wrapper .subscribe-input {
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 20px;
        height: 30px;
        margin: 10px 5px;
        padding: 5px 10px; }
      .Subscribe .Subscribe-content .subscribe-form .all-set {
        padding: 10px; }
        .Subscribe .Subscribe-content .subscribe-form .all-set h4 {
          margin: unset;
          padding-bottom: 10px; }
      .Subscribe .Subscribe-content .subscribe-form .label {
        color: black;
        padding: 10px 0; }
      .Subscribe .Subscribe-content .subscribe-form .warning {
        color: red;
        font-size: 14px;
        min-height: 30px; }
      .Subscribe .Subscribe-content .subscribe-form .verify-input {
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 20px;
        letter-spacing: 10px;
        margin: 10px auto;
        padding: 5px 10px 5px 22px;
        height: 30px;
        width: 93px; }
      .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        height: 41px;
        margin: 10px 0;
        padding: 0 40px;
        text-align: center;
        white-space: nowrap; }
        .irc .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
          background-color: #e6c100; }
        .nrc .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
          background-color: #ce5e00; }
        .generic .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
          background-color: #1e6f16; }
        .mc .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
          background-color: #b30000; }
        .pg .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
          background-color: #2bb1e8; }
        .cuenta .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
          background-color: #1e6f16; }
        .ri .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
          background-color: #aa4928; }
        .irc .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
          color: #000000; }
        .nrc .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
          color: #ffffff; }
        .generic .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
          color: #ffffff; }
        .mc .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
          color: #ffffff; }
        .pg .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
          color: #ffffff; }
        .cuenta .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
          color: #ffffff; }
        .ri .Subscribe .Subscribe-content .subscribe-form .subscribe-button {
          color: #ffffff; }
        .Subscribe .Subscribe-content .subscribe-form .subscribe-button:hover {
          text-decoration: underline; }
          .irc .Subscribe .Subscribe-content .subscribe-form .subscribe-button:hover {
            background-color: #ffda1a; }
          .nrc .Subscribe .Subscribe-content .subscribe-form .subscribe-button:hover {
            background-color: #ff7602; }
          .generic .Subscribe .Subscribe-content .subscribe-form .subscribe-button:hover {
            background-color: #29991f; }
          .mc .Subscribe .Subscribe-content .subscribe-form .subscribe-button:hover {
            background-color: #e60000; }
          .pg .Subscribe .Subscribe-content .subscribe-form .subscribe-button:hover {
            background-color: #59c2ed; }
          .cuenta .Subscribe .Subscribe-content .subscribe-form .subscribe-button:hover {
            background-color: #29991f; }
          .ri .Subscribe .Subscribe-content .subscribe-form .subscribe-button:hover {
            background-color: #cf5c36; }
        .Subscribe .Subscribe-content .subscribe-form .subscribe-button.disabled {
          cursor: default;
          opacity: 0.7; }
          .Subscribe .Subscribe-content .subscribe-form .subscribe-button.disabled:hover {
            text-decoration: none; }
            .irc .Subscribe .Subscribe-content .subscribe-form .subscribe-button.disabled:hover {
              background-color: #e6c100; }
            .nrc .Subscribe .Subscribe-content .subscribe-form .subscribe-button.disabled:hover {
              background-color: #ce5e00; }
            .generic .Subscribe .Subscribe-content .subscribe-form .subscribe-button.disabled:hover {
              background-color: #1e6f16; }
            .mc .Subscribe .Subscribe-content .subscribe-form .subscribe-button.disabled:hover {
              background-color: #b30000; }
            .pg .Subscribe .Subscribe-content .subscribe-form .subscribe-button.disabled:hover {
              background-color: #2bb1e8; }
            .cuenta .Subscribe .Subscribe-content .subscribe-form .subscribe-button.disabled:hover {
              background-color: #1e6f16; }
            .ri .Subscribe .Subscribe-content .subscribe-form .subscribe-button.disabled:hover {
              background-color: #aa4928; }
    .Subscribe .Subscribe-content .how-it-works {
      background-color: #f0f0f0;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-bottom: 20px;
      padding: 10px; }
      .Subscribe .Subscribe-content .how-it-works .title {
        display: flex;
        align-items: center;
        padding-left: 15px; }
      .Subscribe .Subscribe-content .how-it-works h4 {
        align-items: center;
        display: flex; }
        .Subscribe .Subscribe-content .how-it-works h4:hover {
          cursor: pointer;
          text-decoration: underline; }
      @media (max-width: 1000px) {
        .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper {
          flex-direction: column; }
          .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step {
            flex-direction: row !important;
            max-width: unset !important; }
            .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step .step--text {
              padding-right: 20px !important;
              width: 50% !important; }
              .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step .step--text .text {
                border-bottom: unset !important;
                height: unset !important; }
            .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step .step--image {
              max-height: unset !important;
              width: 50% !important; } }
      .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px; }
        .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step {
          align-items: center;
          border: 1px solid #ddd;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          margin: 5px;
          max-width: 200px;
          padding: 20px; }
          .irc .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step {
            background-color: #000000; }
          .nrc .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step {
            background-color: #ffffff; }
          .generic .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step {
            background-color: #ffffff; }
          .mc .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step {
            background-color: #ffffff; }
          .pg .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step {
            background-color: #ffffff; }
          .cuenta .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step {
            background-color: #ffffff; }
          .ri .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step {
            background-color: #ffffff; }
          .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step .step--text {
            align-items: inherit;
            display: inherit;
            flex-direction: column; }
            .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step .step--text .circle {
              align-items: center;
              display: flex;
              font-size: 70px;
              font-weight: bold;
              justify-content: center;
              margin-bottom: 25px;
              height: 100px;
              width: 100px; }
              .irc .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step .step--text .circle {
                color: #ffda1a; }
              .nrc .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step .step--text .circle {
                color: #ff7602; }
              .generic .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step .step--text .circle {
                color: #29991f; }
              .mc .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step .step--text .circle {
                color: #e60000; }
              .pg .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step .step--text .circle {
                color: #59c2ed; }
              .cuenta .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step .step--text .circle {
                color: #29991f; }
              .ri .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step .step--text .circle {
                color: #cf5c36; }
            .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step .step--text .text {
              border-bottom: 1px solid #ddd;
              border-top: 1px solid #ddd;
              font-size: 14px;
              height: 85px;
              margin-bottom: 20px;
              padding: 15px 5px 5px; }
          .Subscribe .Subscribe-content .how-it-works .how-it-works--wrapper .step .step--image img {
            border: 1px solid #eee;
            width: 100%; }
