a {
  text-decoration: none; }

input[type="checkbox"] {
  position: absolute;
  opacity: 0; }

/* Normal Track */
input[type="checkbox"].switch + div {
  vertical-align: middle;
  width: 40px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 999px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition-duration: .4s;
  -webkit-transition-property: background-color, box-shadow;
  box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0.4);
  margin: 15px 1.2em 15px 2.5em; }

/* Checked Track (Green) */
input[type="checkbox"].switch:checked + div {
  width: 40px;
  background-position: 0 0;
  border: 1px solid; }
  .irc input[type="checkbox"].switch:checked + div {
    background-color: #ffda1a; }
  .nrc input[type="checkbox"].switch:checked + div {
    background-color: #ff7602; }
  .generic input[type="checkbox"].switch:checked + div {
    background-color: #29991f; }
  .mc input[type="checkbox"].switch:checked + div {
    background-color: #e60000; }
  .pg input[type="checkbox"].switch:checked + div {
    background-color: #59c2ed; }
  .cuenta input[type="checkbox"].switch:checked + div {
    background-color: #29991f; }
  .ri input[type="checkbox"].switch:checked + div {
    background-color: #cf5c36; }
  .irc input[type="checkbox"].switch:checked + div {
    border-color: #ffda1a; }
  .nrc input[type="checkbox"].switch:checked + div {
    border-color: #ff7602; }
  .generic input[type="checkbox"].switch:checked + div {
    border-color: #29991f; }
  .mc input[type="checkbox"].switch:checked + div {
    border-color: #e60000; }
  .pg input[type="checkbox"].switch:checked + div {
    border-color: #59c2ed; }
  .cuenta input[type="checkbox"].switch:checked + div {
    border-color: #29991f; }
  .ri input[type="checkbox"].switch:checked + div {
    border-color: #cf5c36; }
  .irc input[type="checkbox"].switch:checked + div {
    color: #000000; }
  .nrc input[type="checkbox"].switch:checked + div {
    color: #ffffff; }
  .generic input[type="checkbox"].switch:checked + div {
    color: #ffffff; }
  .mc input[type="checkbox"].switch:checked + div {
    color: #ffffff; }
  .pg input[type="checkbox"].switch:checked + div {
    color: #ffffff; }
  .cuenta input[type="checkbox"].switch:checked + div {
    color: #ffffff; }
  .ri input[type="checkbox"].switch:checked + div {
    color: #ffffff; }
  .irc input[type="checkbox"].switch:checked + div {
    box-shadow: #ffda1a; }
  .nrc input[type="checkbox"].switch:checked + div {
    box-shadow: #ff7602; }
  .generic input[type="checkbox"].switch:checked + div {
    box-shadow: #29991f; }
  .mc input[type="checkbox"].switch:checked + div {
    box-shadow: #e60000; }
  .pg input[type="checkbox"].switch:checked + div {
    box-shadow: #59c2ed; }
  .cuenta input[type="checkbox"].switch:checked + div {
    box-shadow: #29991f; }
  .ri input[type="checkbox"].switch:checked + div {
    box-shadow: #cf5c36; }

/* Tiny Track */
input[type="checkbox"].tinyswitch.switch + div {
  width: 34px;
  height: 18px; }

/* Big Track */
input[type="checkbox"].bigswitch.switch + div {
  width: 50px;
  height: 25px; }

/* Yellow Track */
input[type="checkbox"].yellow.switch:checked + div {
  background-color: #dad61a;
  border: 1px solid #d8d510;
  box-shadow: inset 0 0 0 10px #dad61a; }

/* Normal Knob */
input[type="checkbox"].switch + div > div {
  float: left;
  width: 18px;
  height: 18px;
  border-radius: inherit;
  background: #ffffff;
  -webkit-transition-timing-function: cubic-bezier(0.54, 1.85, 0.5, 1);
  -webkit-transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color, box-shadow;
  -moz-transition-timing-function: cubic-bezier(0.54, 1.85, 0.5, 1);
  -moz-transition-duration: 0.4s;
  -moz-transition-property: transform, background-color;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), 0px 0px 0 1px rgba(0, 0, 0, 0.4);
  pointer-events: none;
  margin-top: 1px;
  margin-left: 1px; }

/* Checked Knob (Blue Style) */
input[type="checkbox"].switch:checked + div > div {
  -webkit-transform: translate3d(20px, 0, 0);
  -moz-transform: translate3d(20px, 0, 0);
  background-color: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), 0px 0px 0 1px #0850ac; }

/* Tiny Knob */
input[type="checkbox"].tinyswitch.switch + div > div {
  width: 16px;
  height: 16px;
  margin-top: 1px; }

/* Checked Tiny Knob (Blue Style) */
input[type="checkbox"].tinyswitch.switch:checked + div > div {
  -webkit-transform: translate3d(16px, 0, 0);
  -moz-transform: translate3d(16px, 0, 0);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), 0px 0px 0 1px #0850ac; }

/* Big Knob */
input[type="checkbox"].bigswitch.switch + div > div {
  width: 23px;
  height: 23px;
  margin-top: 1px; }

/* Checked Big Knob (Blue Style) */
input[type="checkbox"].bigswitch.switch:checked + div > div {
  -webkit-transform: translate3d(25px, 0, 0);
  -moz-transform: translate3d(16px, 0, 0);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3), 0px 0px 0 1px #0850ac; }

/* Green Knob */
input[type="checkbox"].cn.switch:checked + div > div {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), 0 0 0 1px #025e25; }

/* Green Knob */
input[type="checkbox"].ri.switch:checked + div > div {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), 0 0 0 1px #d3be04; }

#location-title {
  color: gray;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  padding: 13px 10px; }

.location-filter {
  padding: 30px;
  text-align: center; }

#location-filter {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  text-align: center;
  padding: 20px;
  text-transform: uppercase;
  display: inline;
  margin-bottom: 20px;
  color: #9b9b9b;
  cursor: pointer; }
  #location-filter[disabled] {
    cursor: not-allowed !important; }

.location-filter-selected {
  color: black !important; }

.separator {
  height: 1px;
  color: #ddd;
  background-color: #ddd;
  border: none;
  margin: 30px 0 auto; }

#locations .location-item, #locations .location-item-selected {
  border: 1px solid #ddd;
  height: 41px; }

#location-list {
  content: "";
  display: table;
  clear: both;
  width: 100%; }

.location-item {
  align-items: center;
  border: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  background-color: #eee;
  height: 30px;
  margin: 10px;
  text-align: center;
  float: left;
  font-size: 13px;
  outline: none;
  padding: 0 15px;
  border-radius: 15px; }
  .location-item:hover {
    border: 1px solid #bbb; }
  .location-item svg {
    margin: 0 6px; }
  .location-item span {
    width: 100%; }

.location-item-selected {
  align-items: center;
  background-color: darkgray;
  border-color: transparent;
  border-radius: 15px;
  color: black;
  cursor: pointer;
  display: flex;
  height: 30px;
  margin: 10px;
  float: left;
  font-size: 13px;
  outline: none;
  padding: 0 15px;
  text-align: center; }
  .location-item-selected svg {
    margin: 0 6px; }
  .location-item-selected span {
    width: 100%; }

.show-more {
  text-align: center; }
  .show-more button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    height: 41px;
    margin: 20px auto 10px;
    width: 120px; }
    .irc .show-more button {
      background-color: #ffda1a; }
    .nrc .show-more button {
      background-color: #ff7602; }
    .generic .show-more button {
      background-color: #29991f; }
    .mc .show-more button {
      background-color: #e60000; }
    .pg .show-more button {
      background-color: #59c2ed; }
    .cuenta .show-more button {
      background-color: #29991f; }
    .ri .show-more button {
      background-color: #cf5c36; }
    .irc .show-more button {
      color: #000000; }
    .nrc .show-more button {
      color: #ffffff; }
    .generic .show-more button {
      color: #ffffff; }
    .mc .show-more button {
      color: #ffffff; }
    .pg .show-more button {
      color: #ffffff; }
    .cuenta .show-more button {
      color: #ffffff; }
    .ri .show-more button {
      color: #ffffff; }
    .show-more button:hover {
      text-decoration: underline; }
      .irc .show-more button:hover {
        background-color: #ffda1a; }
      .nrc .show-more button:hover {
        background-color: #ff7602; }
      .generic .show-more button:hover {
        background-color: #29991f; }
      .mc .show-more button:hover {
        background-color: #e60000; }
      .pg .show-more button:hover {
        background-color: #59c2ed; }
      .cuenta .show-more button:hover {
        background-color: #29991f; }
      .ri .show-more button:hover {
        background-color: #cf5c36; }

.show-services {
  border: 1px solid #ddd;
  color: black;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  height: 41px;
  width: 50%;
  border-radius: 5px;
  outline: 0;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 30px;
  margin-bottom: 20px;
  cursor: pointer; }
  .irc .show-services {
    background-color: #ffda1a; }
  .nrc .show-services {
    background-color: #ff7602; }
  .generic .show-services {
    background-color: #29991f; }
  .mc .show-services {
    background-color: #e60000; }
  .pg .show-services {
    background-color: #59c2ed; }
  .cuenta .show-services {
    background-color: #29991f; }
  .ri .show-services {
    background-color: #cf5c36; }
  .irc .show-services {
    color: #000000; }
  .nrc .show-services {
    color: #ffffff; }
  .generic .show-services {
    color: #ffffff; }
  .mc .show-services {
    color: #ffffff; }
  .pg .show-services {
    color: #ffffff; }
  .cuenta .show-services {
    color: #ffffff; }
  .ri .show-services {
    color: #ffffff; }

#button-search {
  width: 100%;
  text-align: center; }

.btn-close {
  position: absolute;
  padding: 20px;
  color: grey;
  font-family: Roboto;
  cursor: pointer; }

.stronger {
  font-weight: 500; }

.departments ul li:first-child hr {
  display: none; }

.overlay {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 0; }
