a {
  text-decoration: none; }

.TopCategories .top-container .title-line.rtl {
  margin-right: 0;
  margin-left: 20px; }

.TopCategories .top-container .title-line.ltr {
  margin-left: 0;
  margin-right: 20px; }

.TopCategories h3 {
  font-size: 25px;
  text-transform: none; }
  @media (max-width: 1000px) {
    .TopCategories h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      text-align: left; } }

.ltr .TopCategories s {
  float: right; }

.rtl .TopCategories s {
  float: left; }

@media (min-width: 1000px) {
  .TopCategories .categories-container {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-column-gap: 4%; } }

.TopCategories .categories-container .TopCategory {
  display: grid;
  grid-template-columns: 20% 78% 2%;
  text-align: left;
  color: black; }
  @media (min-width: 1000px) {
    .TopCategories .categories-container .TopCategory {
      grid-template-columns: 20% 70% 10%;
      grid-template-rows: 0; } }
  .TopCategories .categories-container .TopCategory .icon {
    grid-column-start: 1;
    display: flex; }
  .TopCategories .categories-container .TopCategory-title {
    text-align: left;
    text-decoration: underline;
    text-transform: none !important; }
  .TopCategories .categories-container .TopCategory-description {
    text-align: left;
    grid-column-start: 2; }
